import React, { useCallback, useMemo, useRef } from "react";
import NotificationManager from "../../components/utils/notificationManger";
import { useState } from "react";
import { utilisateur } from "../../services/fetchData";
import { usePostMutation } from "../../components/utils/useCustomQuery ";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import TableDesny from "../../components/customComponents/tableDesny";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
import { ajusteJSON } from "../../components/utils/functionChange";

const Profil = (params) => {
  const theUser = params.user;
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [formData, setFormData] = React.useState(theUser);
  const [formDataPassWord, setFormDataPassWord] = React.useState();
  const [user, setUser] = React.useState(theUser);
  const [errorPassWord, setErrorPassWord] = React.useState("");

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };
  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };
  const addMutation = usePostMutation(["utilisateur"], utilisateur);

  const handleSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
        setUser(formData);
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  const formRef = useRef();
  const handleSubmitPassWordChange = async (e) => {
    e.preventDefault();
    setFormDataPassWord({
      ...formDataPassWord,
      idUtilisateur: user.id,
      login: user.login,
    });

    addMutation.mutate(formDataPassWord, {
      onSuccess: () => {
        showSuccessNotification();
        setErrorPassWord("");
        formRef.current.reset();
      },
      onError: (error) => {
        setErrorPassWord(ajusteJSON(error.response.data));
        showErrorNotification();
      },
    });
  };
  const handleChange = (value, field) => {
    setFormData({
      ...formData,
      idUtilisateur: user.id,
      [field]: value,
    });
  };

  const handleChangePassWord = (value, field) => {
    setFormDataPassWord({
      ...formDataPassWord,
      idUtilisateur: user.id,
      login: user.login,
      [field]: value,
    });
  };


  // TABLE
  const [newRowData, setNewRowData] = useState([]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "40%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", background: "red !important" }),
    []
  );
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  let dataTable = [
    { field: "idUtilisateur", hide: true, editable: false },
    { field: "nom", editable: false },
    { field: "prenom", editable: false },
    { field: "login", editable: false },
    { field: "role", editable: false },
    { field: "cle", hide: true },
  ];

  return (
    <>
      <div className="container-xl px-4 mt-5 pt-5">
        <div className="row">
          <div className="card mb-4 col-5 ms-5">
            <div className="card-header">Details du compte</div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="inputUsername">
                    Login de connexion
                  </label>
                  <input
                    className="form-control"
                    id="inputUsername"
                    type="text"
                    placeholder="Enter your username"
                    defaultValue={formData ? formData.login : user.login}
                    disabled
                  />
                </div>
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1">Nom</label>
                    <input
                      className="form-control"
                      id="inputFirstName"
                      type="text"
                      placeholder="Enter your first name"
                      value={formData ? formData.nom : user.nom}
                      onChange={(e) => {
                        handleChange(e.target.value, "nom");
                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="small mb-1">Prenom</label>
                    <input
                      className="form-control"
                      id="inputLastName"
                      type="text"
                      placeholder="Enter your last name"
                      value={formData ? formData.prenom : user.prenom}
                      onChange={(e) => {
                        handleChange(e.target.value, "prenom");
                      }}
                    />
                  </div>
                </div>
                <button className="btn btn-primary mt-5" type="submit">
                  Sauvergarder
                </button>
              </form>
            </div>
          </div>

          <div className="card mb-4 col-5 ms-5">
            <div className="card-header">Changer mot de passe</div>
            <div className="card-body">
              <form ref={formRef} onSubmit={handleSubmitPassWordChange}>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="inputUsername">
                    Ancien mot de passe
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => {
                      handleChangePassWord(e.target.value, "ancienMotDePasse");
                    }}
                    required
                    type="password"
                    placeholder="Enter votre mot de passe"
                  />
                </div>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="inputFirstName">
                    Nouveau mot de passe
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => {
                      handleChangePassWord(e.target.value, "nouveauMotDePasse");
                    }}
                    required
                    type="password"
                    placeholder="Enter votre nouveau mot de passe"
                  />
                </div>
                <div className="mb-3">
                  <label className="small mb-1" htmlFor="inputLastName">
                    Confirmer mot de passe
                  </label>
                  <input
                    className="form-control"
                    onChange={(e) => {
                      handleChangePassWord(
                        e.target.value,
                        "confirmationMotDePasse"
                      );
                    }}
                    required
                    type="password"
                    placeholder="Confirmer votre mot de passe"
                  />
                </div>
                <p className="text-danger">{errorPassWord ? errorPassWord[0].message : ""}</p>
                <button className="btn btn-primary" type="submit">
                  Sauvergarder
                </button>
              </form>
            </div>
          </div>
        </div>
        <NotificationManager notificationQueue={notificationQueue} />
      </div>

      {theUser.role === "Admin" && (
        <div className="container-fluid" style={containerStyle}>
          <div className=" text-end pb-2">
            <InputGroup
              className="ms-auto  custom-placeholder"
              style={{ width: "37%" }}
            >
              <Form.Control
                type="text"
                placeholder="Rechercher ..."
                onChange={handleSearchChange}
                className="border-none rounded-start "
                style={{ background: "", border: "solid 1px white" }}
              />

              <InputGroup.Text
                id="basic-addon2"
                style={{ background: "none", borderLeft: "none !important" }}
                className="rounded-end"
              >
                <SearchIcon className="text-light" />
              </InputGroup.Text>
              <div className="ms-2">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <MoreHorizIcon />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item style={{ fontSize: "0.9rem" }}>
                      <Link
                        to="/update_user/new"
                        className="text-decoration-none text-dark"
                      >
                        Ajouter un utilisateur
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </InputGroup>
          </div>
          <TableDesny
            gridStyle={gridStyle}
            dataTable={dataTable}
            searchKeys={["nom", "prenom", "login"]}
            endPoint="utilisateur"
            searchValue={searchValue}
            idItemClick="idUtilisateur"
            page="update_user"
            onRowDataChange={(newRowData) => {
              setNewRowData(newRowData);
            }}
            clickable={true}
          />
        </div>
      )}
    </>
  );
};

export default Profil;
