import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarCharts = ({
    barLabels,
    data,
    datasetLabel,
    label,
    dataKey,
    indexKey,
    valueKey,
    borderRadius, 
    borderWidth,
    stepSize
}) => {

    const aggregateDataBy = (data) => {
        const aggregatedData = {};
        data.forEach((item) => {
            const label = item[datasetLabel];
            if (!aggregatedData[label]) {
                aggregatedData[label] = {
                    label,
                    data: Array(barLabels.length).fill(0),
                    backgroundColor: item.backgroundColor, // Couleur de fond
                    borderColor: item.borderColor,  
                };
            }
            const dataValue = parseFloat(item[valueKey]);
            const index = item[indexKey] - 1;
            aggregatedData[label].data[index] = dataValue;
        });
        return Object.values(aggregatedData);
    };

    const aggregatedData = aggregateDataBy(data);

    const chartData = {
        labels: barLabels,
        datasets: aggregatedData.map((item) => ({
            label: item.label,
            data: item.data,
            backgroundColor: item.backgroundColor,
            borderColor: item.borderColor,
            borderWidth: borderWidth,
            borderRadius: borderRadius,
        })),
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: stepSize,  // Cela définit l'intervalle entre les ticks
                    callback: function(value) {
                        return value.toFixed(0);  // Cela garantit qu'aucune valeur décimale n'est affichée
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "bottom",
            },
            title: {
                display: true,
                text: label,
            },
            tooltip: {
                enabled: true,
            },
        },
    };
    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default BarCharts;
