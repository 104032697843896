import React from 'react';
import { Spinner } from 'react-bootstrap';

const Reload = () => {
  return (
    <div className='w-100 position-relative' style={{height: '100vh', opacity:'1'}}>
      <div className='position-absolute top-50 start-50 translate-middle'>
      <Spinner animation="border text-danger" role="status"></Spinner>
      </div>
    </div>
  );
};

export default Reload;