import React, { useState } from "react";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CardsDesny from "../../customComponents/cardsDesny";
import { useCustomQuery } from "../../utils/useCustomQuery ";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";

const WidgetEmploye = () => {
  const [error, setError] = useState(null);

  const {data : AllEmployers, isLoading} = useCustomQuery(["nbAll_employes"], "nbAll_employes", setError);

  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="row">
      <CardsDesny title="Total Employé Brazzaville" value={AllEmployers ? AllEmployers.brazzaville_count : 0} valueTitle="" IconComponent={PeopleOutlineIcon} colorIconComponent="text-success" gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2" />
      <CardsDesny title="Total Employé Dolisie" value={AllEmployers ? AllEmployers.dolisie_count : 0} valueTitle="" IconComponent={PeopleOutlineIcon} colorIconComponent="text-danger" gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2" />
      <CardsDesny title="Total Employé Pointe-Noire" value={AllEmployers ? AllEmployers.pointe_noire_count : 0} valueTitle="" IconComponent={PeopleOutlineIcon} colorIconComponent="text-primary" gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2" />
    </div>
  );
};

export default WidgetEmploye;
