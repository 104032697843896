import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import {
  getElement,
  addEmploye,
} from "../../../../services/fetchData";
import { formatEdit } from "../../../utils/numberFormat";
import "../../../../pages/global/style_global.scss";
import NotificationManager from "../../../utils/notificationManger";
import MenuIcon from '@mui/icons-material/Menu';
import Reload from "../../../utils/reload";
import { useDeleteMutation, usePostMutation } from "../../../utils/useCustomQuery ";
import ConfirmYesNo from "../../../modal/confirmYesNo";


const UpdateEmploye = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentEmployeToDelete, setCurrentEmployeToDelete] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);
  // eslint-disable-next-line
  const [isRequired, setIsRequired] = useState([]);
  // eslint-disable-next-line
  const [isActif, setIsActif] = useState(
    employeeData ? employeeData.actif === 1 : false
  );
  const saveButtonRef = useRef();
  
  const currentURL = window.location.href;
  const page = currentURL.split("/");
  const nIdEmployeLink = page.pop();

  const initialFormData = useMemo(() => ({
    civilite: "",
    nom: "",
    prenom: "",
    actif: "",
    fonction: "",
    dateEmbauche: "",
    salaire: "",
    adresse: "",
    ville: "",
    pays: "",
    numeroTelephone: "",
    nom_urgent: "",
    prenom_urgent: "",
    relation_urgent: "",
    tel_urgent: "",
    idAgence: "",
    idEmploye: nIdEmployeLink,
  }), [nIdEmployeLink]);

  const [formData, setFormData] = useState(initialFormData);
  
  useEffect(() => {
    getElement("employes", "idEmploye", id, (data) => {
      setEmployeeData(data);

      // Mettez à jour initialFormData avec les données de l'employé si elles existent
      if (data) {
        setFormData({
          ...initialFormData,
          civilite: data.civilite || initialFormData.civilite,
          nom: data.nom || initialFormData.nom,
          prenom: data.prenom || initialFormData.prenom,
          actif: data.actif || initialFormData.actif,
          fonction: data.fonction || initialFormData.fonction,
          dateEmbauche: data.dateEmbauche || initialFormData.dateEmbauche,
          salaire: data.salaire || initialFormData.salaire,
          adresse: data.adresse || initialFormData.adresse,
          ville: data.ville || initialFormData.ville,
          pays: data.pays || initialFormData.pays,
          numeroTelephone: data.numeroTelephone || initialFormData.numeroTelephone,
          nom_urgent: data.nom_urgent || initialFormData.nom_urgent,
          prenom_urgent: data.prenom_urgent || initialFormData.prenom_urgent,
          relation_urgent: data.relation_urgent || initialFormData.relation_urgent,
          tel_urgent: data.tel_urgent || initialFormData.tel_urgent,
          idAgence: data.idAgence || initialFormData.idAgence,
          idEmploye: nIdEmployeLink,
        });
      }
    });

    if (id === "new") {
      setIsEditMode(true);
    }
    setIsActif(employeeData ? employeeData.actif === 1 : false);
      // eslint-disable-next-line
  }, [id, nIdEmployeLink, initialFormData]);

  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData, [fieldName]: value,
    })); 
    setEmployeeData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  }

  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleSaveClick = () => {
    if (!isRequired) {
      setIsEditMode(false);
      showSuccessNotification();
    }
  };
  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const handleActifChange = (event) => {
    const newValue = event.target.checked;
    setIsActif(newValue);
    // Mettez à jour l'état de l'employé en fonction de la nouvelle valeur
    const newActifValue = newValue ? 1 : 0;
    handleFieldChange({ target: { value: newActifValue } }, "actif");
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const simulateSaveButtonClick = () => {
    saveButtonRef.current.click();
  };

  const addMutation = usePostMutation(["employes"], addEmploye);
  const deleteMutation = useDeleteMutation(["employes"]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
        setTimeout(() => {
          navigate("/list_employees");
        }, 700);
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };
    const handleOpenDeleteModal = (employeeData) => {
      setCurrentEmployeToDelete(employeeData);
      setShowConfirmModal(true);
    };
   
    const handleBtnDelete = () => {
      if (currentEmployeToDelete) {
        deleteMutation.mutate({
          tableName: "employes",
          idFieldName: "idEmploye",
          id: nIdEmployeLink,
          cle: currentEmployeToDelete.cle,
        }, {
          onSuccess: () => {
            setShowConfirmModal(false);
            showSuccessNotification();
            setTimeout(() => {
              navigate("/list_employees");
            }, 700);
          },
          onError: () => {
            showErrorNotification();
          }, 
          
        });
      }
    };
    
  if (!employeeData && id !== "new") return <Reload />;


  return (
    <div>
        <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Liste Employés</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink to="/list_employees" className="text-secondary text-decoration-none hover-link-in">Tous les employés</NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">{employeeData ? `${employeeData.nom} ${employeeData.prenom}` : "Nouvel employé"}</span>
      </p>
      <div className="container-fluid my-2">
      <Form className="pt-3" onSubmit={handleSubmit}>
        <Card body>
          <div className="d-flex">
            <Card.Title className="pb-3 title">Détails de l'employé</Card.Title>
            {isEditMode ? (
              <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                Mode modification
              </Alert>
            ) : (
              <p></p>
            )}
            <Dropdown className="ms-auto">
              <Dropdown.Toggle id="dropdown-basic">Outils <MenuIcon/></Dropdown.Toggle>
              <Dropdown.Menu>
                {isEditMode ? (
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    type="submit"
                    onClick={() => {
                      simulateSaveButtonClick();
                      handleSaveClick();
                    }}
                  >
                    Enregistré
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={handleEditClick}
                  >
                    Modifier
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={handleEditClick}
                  >
                    Contrat
                  </Dropdown.Item>
                <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={handleEditClick}
                  >
                    Fiche de paie
                  </Dropdown.Item>
                <Dropdown.Item style={{ fontSize: "0.9rem" }} onClick={() => handleOpenDeleteModal(employeeData)}>
                  Supprimer
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <p className="" style={{ fontSize: "0.8rem !important" }}>
            Le formulaire d'informations personnelles sur les employés collecte
            les informations essentielles nécessaires pour gérer leur profil au
            sein de l'entreprise.
          </p>
          <hr />

          <Card.Title className="pb-1 title">
            Informations Personnelles
          </Card.Title>
          <p className="fw-semibold">
            Veuillez fournir ces informations afin de nous permettre de
            conserver un dossier actualisé sur les données personnelles de nos
            employés.
          </p>

          <Form.Group as={Row} className="mb-3" controlId="fullName">
            <Form.Label column sm="2" lg="2" md="2">
              Civilité
            </Form.Label>
            <Col sm="5">
              <Form.Select
                aria-label="Civilité"
                value={employeeData ? employeeData.civilite : formData.civilite} // Vous récupérez la valeur de civilité depuis formData
                onChange={(event) => handleFieldChange(event, "civilite")} // Lorsque l'utilisateur change la valeur, vous appelez handleFieldChange pour mettre à jour formData
                disabled={!isEditMode}
                required
              >
                <option value="" disabled>
                  Choisir civilité
                </option>
                <option value="Mr">Mr</option>
                <option value="Mme">Mme</option>
                <option value="Autres">Autres</option>
              </Form.Select>
            </Col>
            <Col sm="5">
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Actif"
                checked={ employeeData ? employeeData.actif : false }
                onChange={(event) => handleActifChange(event, "actif")}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-2" controlId="fullName">
            <Form.Label column sm="2" lg="2" md="2">
              Nom complet de l'employé(e)
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Nom"
                value={employeeData ? employeeData.nom : formData.nom}
                onChange={(event) => handleFieldChange(event, "nom")}
                disabled={!isEditMode}
                required={isRequired}
              />
            </Col>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Prénom"
                value={employeeData ? employeeData.prenom : formData.prenom}
                onChange={(event) => handleFieldChange(event, "prenom")}
                disabled={!isEditMode}
                required={isRequired}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="tel">
            <Form.Label column sm="2" lg="2" md="2">
              Téléphone
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Numéro de téléphone"
                value={
                  employeeData
                    ? employeeData.numeroTelephone
                    : formData.numeroTelephone
                }
                onChange={(event) =>
                  handleFieldChange(event, "numeroTelephone")
                }
                disabled={!isEditMode}
                required={isRequired}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-4" controlId="adress">
            <Form.Label column sm="2" lg="2" md="2">
              Adresse
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder="Adresse"
                value={employeeData ? employeeData.adresse : formData.adresse}
                onChange={(event) => handleFieldChange(event, "adresse")}
                disabled={!isEditMode}
                required={isRequired}
              />
            </Col>
            <Form.Label column sm="2" lg="2" md="2"></Form.Label>
            <Col sm="5" className="mt-3">
              <Form.Control
                type="text"
                placeholder="Ville"
                value={employeeData ? employeeData.ville : formData.ville}
                onChange={(event) => handleFieldChange(event, "ville")}
                disabled={!isEditMode}
                required={isRequired}
              />
            </Col>
            <Col sm="5" className="mt-3">
              <Form.Select
                aria-label="Pays"
                value={formData.pays}
                onChange={(event) => handleFieldChange(event, "pays")}
                disabled={!isEditMode}
                required={isRequired}
              >
                <option value="" disabled>
                  Choisir pays
                </option>
                <option value="Congo">Congo</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <hr />

          <Card.Title className="pb-1 title">
            Informations sur le contact d'urgence
          </Card.Title>
          <p className="fw-semibold">
            Il est essentiel de disposer des coordonnées de la personne à
            contacter en cas d'urgence.
          </p>
          <Form.Group as={Row} className="mb-3" controlId="fullName">
            <Form.Label column sm="2" lg="2" md="2">
              Nom du contact d'urgence
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Nom du contact"
                value={
                  employeeData ? employeeData.nom_urgent : formData.nom_urgent
                }
                onChange={(event) => handleFieldChange(event, "nom_urgent")}
                disabled={!isEditMode}
              />
            </Col>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Prénom du contact"
                value={
                  employeeData
                    ? employeeData.prenom_urgent
                    : formData.prenom_urgent
                }
                onChange={(event) => handleFieldChange(event, "prenom_urgent")}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-4" controlId="">
          <Form.Label column sm="2" lg="2" md="2">
              Rélation du contact d'urgence
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Rélation du contact"
                value={
                  employeeData
                    ? employeeData.relation_urgent
                    : formData.relation_urgent
                }
                onChange={(event) =>
                  handleFieldChange(event, "relation_urgent")
                }
                disabled={!isEditMode}
              />
            </Col>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Numéro téléphone du contact"
                value={
                  employeeData ? employeeData.tel_urgent : formData.tel_urgent
                }
                onChange={(event) => handleFieldChange(event, "tel_urgent")}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <hr />

          <Card.Title className="pb-1 title">
            Informations Entreprise
          </Card.Title>
          <p className="fw-semibold">
            Cette section contient les détails essentiels relatifs à
            l'entreprise et l'employé(e).
          </p>

          <Form.Group as={Row} className="mb-3" controlId="">
            <Form.Label column sm="2" lg="2" md="2">
              Emploi
            </Form.Label>
            <Col sm="5">
              <Form.Select
                aria-label="Fonction"
                value={employeeData ? employeeData.fonction : formData.fonction}
                onChange={(event) => handleFieldChange(event, "fonction")}
                disabled={!isEditMode}
                required={isRequired}
              >
                <option value="" disabled>
                  Choisir fonction
                </option>
                <option value="Agent de Maintenance des Véhicules">
                  Agent de Maintenance des Véhicules
                </option>
                <option value="Agent de Réservation">
                  Agent de Réservation
                </option>
                <option value="Agent de Sécurité">Agent de Sécurité</option>
                <option value="Agent d'Accueil">Agent d'Accueil</option>
                <option value="Agent de Service Client">
                  Agent de Service Client
                </option>
                <option value="Agent des Ressources Humaines">
                  Agent des Ressources Humaines
                </option>
                <option value="Bagagiste">Bagagiste</option>
                <option value="Chauffeur">Chauffeur</option>
                <option value="Directeur">Directeur</option>
                <option value="Responsable des Finances">
                  Agent des Ressources Humaines
                </option>
                <option value="Responsable des Opérations">
                  Responsable des Opérations
                </option>
                <option value="Administrateur">
                  Administrateur
                </option>
              </Form.Select>
            </Col>
            <Col sm="5">
              <Form.Select
                aria-label="Agence"
                value={employeeData ? employeeData.idAgence : formData.idAgence}
                onChange={(event) => handleFieldChange(event, "idAgence")}
                disabled={!isEditMode}
              >
                <option value="" selected disabled>
                  Choisir l'agence
                </option>
                <option value="1">Agence Brazzaville</option>
                <option value="2">Agence Pointe-Noire</option>
                <option value="3">Agence Dolisie</option>
              </Form.Select>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-4" controlId="fullName">
            <Form.Label column sm="2" lg="2" md="2">Date d'embauche</Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                placeholder="Date d'embauche"
                value={
                  employeeData
                    ? employeeData.dateEmbauche
                    : formData.dateEmbauche
                }
                onChange={(event) => handleFieldChange(event, "dateEmbauche")}
                disabled={!isEditMode}
                required={isRequired}
              />
            </Col>
            <Col sm="5">
              <Form.Control
                type="text"
                placeholder="Salaire"
                value={
                  employeeData
                    ? formatEdit(employeeData.salaire)
                    : formatEdit(formData.salaire)
                }
                onChange={(event) => handleFieldChange(event, "salaire")}
                disabled={!isEditMode}
                required={isRequired}
              />
              <Form.Control
                type="text"
                placeholder="id"
                value={formData.idEmploye}
                onChange={(event) => handleFieldChange(event, "idEmploye")}
                disabled={!isEditMode}
                required={isRequired}
                style={{display:'none'}}
              />
            </Col>
          </Form.Group>
        </Card>
        <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
          Enregistrer
        </Button>
      </Form>
      <NotificationManager notificationQueue={notificationQueue} />
      <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer cet employé ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutation.isPending }
      />
    </div>
    </div>
  );
};

export default UpdateEmploye;
