import React, { useCallback, useMemo, useState } from "react";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Dropdown, Form, InputGroup } from "react-bootstrap";
import { generatePDF } from "../../../services/fetchData";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableDesny from "../../customComponents/tableDesny";
const TableVoyageurs = () => {
    const [newRowData, setNewRowData] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(
      () => ({ height: "100%", width: "100%", background: "red !important" }),
      []
    );
    const [searchValue, setSearchValue] = useState(""); // État pour stocker la valeur de recherche
    // Gestionnaire d'événements pour la modification de la valeur de recherche
    const handleSearchChange = useCallback((event) => {
      setSearchValue(event.target.value);
    }, []);

    
  let dataTable = [
    {field: "idVoyageur",hide: true,editable: false},
    {field: "civilite",editable: false,headerName: "Civilite"},
    {field: "nom",editable: false,headerName: "Nom"},
    { field: "prenom", headerName: "Prenom", editable: false },
    { field: "date_naissance", headerName: "Date de naissance", editable: false },
    { field: "pays", headerName: "Pays", editable: false },
    { field: "numeroTelephone", headerName: "Téléphone", editable: false },
    { field: "email", headerName: "E-mail", editable: false },
    {
      field: "nom_agence",
      headerName: "Agence",
      editable: false,
      cellStyle: function (params) {
        if (params.value === "Agence Brazzaville") {
          return { color: "#10b981" };
        }
        if (params.value === "Agence Pointe-Noire") {
          return { color: "#7e91ff" };
        }
        if (params.value === "Agence Dolisie") {
          return { color: "#dc3545" };
        }
        return null;
      },
    },
    { field: "cle", hide: true },
  ];

    return (
       <div style={containerStyle} >
      <Button
        type="button"
        // onClick={() =>
        //   generatePDF("generate-EmployePDF", "Liste_employes", newRowData)
        // }
        style={{ float: "left", fontSize: "0.8rem" }}
      >
        <PictureAsPdfIcon /> Liste des voyageurs PDF
      </Button>
      <div className=" text-end pb-2">
        <InputGroup
          className="ms-auto  custom-placeholder"
          style={{ width: "37%" }}
        >
          <Form.Control
            type="text"
            placeholder="Rechercher ..."
            onChange={handleSearchChange}
            className="border-none rounded-start "
            style={{ background: "", border: "solid 1px white" }}
          />

          <InputGroup.Text
            id="basic-addon2"
            style={{ background: "none", borderLeft: "none !important" }}
            className="rounded-end"
          >
            <SearchIcon className="text-light" />
          </InputGroup.Text>
          <div className="ms-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <MoreHorizIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item style={{ fontSize: "0.9rem" }}>
                  <Link
                    to="/update_voyageur/new"
                    className="text-decoration-none text-dark"
                  >
                    Ajouter un voyageur
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </InputGroup>
      </div>
      <TableDesny
        gridStyle={gridStyle}
        dataTable={dataTable}
        searchKeys={["adresse", "date_naissance", "email", "nom_agence", "numeroTelephone", "pays", "prenom", "nom", "ville"]}
        endPoint="all_Voyageurs"
        searchValue={searchValue}
        idItemClick="idVoyageur"
        page="update_voyageur"
        onRowDataChange={(newRowData) => {
          setNewRowData(newRowData);
        }}
        clickable={true}
      />
    </div>
    );
};

export default TableVoyageurs;