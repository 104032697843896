import React from 'react';
import  CongoPng from '../../../assets/images/congo.png';

const DefaultBigWidget = () => {
    return (
        <div className='text-center text-white no-tablette'>
            <img src={CongoPng} alt="Congo PNG" className='me-auto'/>
            <p className='p-4'>Accédez à toutes les informations essentielles et prenez des décisions éclairées pour assurer le bon fonctionnement de Desny Express.</p>
        </div>
    );
};

export default DefaultBigWidget;