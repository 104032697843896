import React, {useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { fetchData } from "../../../services/fetchData";
import { ErrorConnexion } from "../../modal/error500";
import Reload from "../../utils/reload";
import { useQuery } from "@tanstack/react-query";
ChartJS.register(ArcElement, Tooltip, Legend);

const AnalyticWidgetDonut = () => {
  const [error, setError] = useState(null);
  const { data: nbReservationYearAgence, isLoading } = useQuery({
    queryKey: ["nbTotal_reservationYears_Agence"],
    queryFn: () => fetchData("nbTotal_reservationYears_Agence"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 60,
    onError: (error) => {
      setError(error.message);
    },
  });



  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true, // Utiliser des formes de point personnalisées
        },
      },
      title: {
        display: true,
        text: "Graphique de réseravtion",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const agence = ["Brazzaville", "Pointe-Noire", "Dolisie"];
  const aggregateData = (data) => {
    const aggregatedData = {
      data: [],
      backgroundColor: [],
      borderColor: "none", // Couleur de la bordure du donut
      cutout: "50%", // Taille de l'intérieur du donut (80%)
      hoverOffset: 10, // Espacement au survol
      borderWidth: 1,
    };

    data && data.map((item) => {
      const dataValue = parseFloat(item.nombre);
      aggregatedData.data.push(dataValue);
      aggregatedData.backgroundColor.push(item.backgroundColor);
    });

    return aggregatedData;
  };
  let dataDonut = aggregateData(nbReservationYearAgence);
  const data = {
    labels: agence,
    datasets: [dataDonut],
  };

  const plugins = {
    beforeInit(chart) {
      chart.data.labels.forEach((label, index, labels) => {
        const dataset = chart.data.datasets[0];
        const total = dataset.data.reduce((acc, value) => acc + value, 0);
        const currentValue = dataset.data[index];
        const percentage = parseFloat(
          ((currentValue / total) * 100).toFixed(1)
        );
        labels[index] = `${label} - ${percentage}%`;
      });
    },
  };

  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="ps-4 tablette-special-donut">
      <Card style={{ background: "#232e45", width: "100%" }}>
        <p className="text-start  text-white  pt-3  ps-3 pb-2 mb-0">
          Réservations
        </p>
        <Card.Body className="d-flex justify-content-center pt-0">
          <div className="pc-donut" style={{ width: "70%" }}>
            <Doughnut data={data} options={options} plugins={plugins} />
          </div>
        </Card.Body>
        <div className="container p-1 pe-3 ps-3 pb-4 mb-2 d-flex justify-content-between">
          {nbReservationYearAgence && nbReservationYearAgence.map((item) => (
            <Card key={item.nom_agence}
              style={{ width: "30%", background: "none", fontSize: "0.8rem" }}
              className="border border-secondary "
            >
              <Card.Body className="p-2">
                <Card.Title
                  className="text-white pt-1"
                  style={{ fontSize: "1rem" }}
                >
                  <RadioButtonUncheckedIcon
                    className="rounded-circle "
                    style={{
                      color: item.borderColor,
                      background: item.borderColor,
                      fontSize: "0.7rem",
                    }}
                  />
                  {" "}{item.nom_agence}
                </Card.Title>
                <ListGroup>
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-start text-white border-none p-0"
                    style={{ background: "none", border: "none" }}
                  >
                    <div className="me-auto">Terminés</div>
                    <p className="fw-semibold">{item.nb_statut_termine}</p>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-start text-white border-none p-0"
                    style={{ background: "none", border: "none" }}
                  >
                    <div className="me-auto">En cours</div>
                    <p className="fw-semibold">{item.nb_statut_encours}</p>
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="d-flex justify-content-between align-items-start text-white border-none p-0"
                    style={{ background: "none", border: "none" }}
                  >
                    <div className="me-auto">Annulés</div>
                    <p className="fw-semibold">{item.nb_statut_annule}</p>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default AnalyticWidgetDonut;
