import React, { useState } from "react";
import { Accordion, Button, Form, Spinner } from "react-bootstrap";
import NotificationManager from "../../../utils/notificationManger";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addActivity,
  fetchData,
  deleteElement,
} from "../../../../services/fetchData";
import Reload from "../../../utils/reload";
import ConfirmYesNo from "../../../modal/confirmYesNo";

const ActivityInsert = () => {
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentActivityToDelete, setCurrentActivityToDelete] = useState(null);

  const handleOpenDeleteModal = (activity) => {
    setCurrentActivityToDelete(activity);
    setShowConfirmModal(true);
  };

  const [formData, setFormData] = useState({
    intitule: "",
    description: "",
  });

  const queryClient = useQueryClient();
  const { data: allActivity, isLoading } = useQuery({
    queryKey: ["activity"],
    queryFn: () => fetchData("activityAll"),
      staleTime: 1000 * 60 * 5, // Les données restent fraîches pendant 5 minutes
      cacheTime: 1000 * 60 * 60, // Les données restent en cache pendant 1 heure, même si inactives
  });

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const addMutation = useMutation({
    mutationFn: (formData) =>
      addActivity(formData.intitule, formData.description),
    onSuccess: () => {
      queryClient.invalidateQueries(["activityAll"]);
      showSuccessNotification();
      setFormData({ intitule: "", description: "" });
    },
    onError: () => {
      showErrorNotification();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: ({ tableName, idFieldName, id, cle }) =>
      deleteElement(tableName, idFieldName, id, cle),
    onSuccess: () => {
      queryClient.invalidateQueries(["activity"]);
      showSuccessNotification();
    },
    onError: () => {
      showErrorNotification();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    addMutation.mutate(formData);
  };

  const handleBtnDelete = () => {
    if (currentActivityToDelete) {
      deleteMutation.mutate({
        tableName: "activity",
        idFieldName: "idActivity",
        id: currentActivityToDelete.idActivity,
        cle: currentActivityToDelete.cle,
      }, {
        onSuccess: () => {
          setShowConfirmModal(false);
          showSuccessNotification();
        },
        onError: () => {
          showErrorNotification();
        }
      });
    }
  };

  if (isLoading) return <Reload />;

  return (
    <div className="row w-100" style={{ overflow: "auto", height: "100vh" }}>
      <div className="col-lg-7 col-md-12">
        <div className="m-4 bg-light rounded p-4">
          <Form className="w-100 text-dark" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Titre de l'activité</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Titre de l'activité"
                value={formData.intitule}
                onChange={(e) =>
                  setFormData({ ...formData, intitule: e.target.value })
                }
              />
              <Form.Text className="text-muted">
                Ajouter une nouvelle activité sur l'application
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Description</Form.Label>
              <Form.Control
                className="ps-3 pt-1"
                required
                as="textarea"
                placeholder="Description de l'activité"
                style={{ width: "500% !important" }}
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                cols={0}
                rows={7}
              />
            </Form.Group>
            <Button type="submit">
              Ajouter{" "}
              {addMutation.isPending ? (
                <Spinner animation="border" size="sm" />
              ) : (
                ""
              )}{" "}
            </Button>
          </Form>
          <NotificationManager notificationQueue={notificationQueue} />
        </div>
      </div>
      <div className="col-lg-5 col-md-12">
        <div
          className="m-4 bg-light rounded p-3"
          style={{ overflow: "auto", height: "60vh" }}
        >
          {allActivity &&
            allActivity.map((activity) => (
              <Accordion key={activity.idActivity}>
                <Accordion.Item eventKey="0" className="mt-2">
                  <Accordion.Header>{activity.intitule}</Accordion.Header>
                  <Accordion.Body className="d-flex">
                    <span className="w-100" style={{ textAlign: "justify" }}>
                      {" "}
                      {activity.description}
                    </span>
                    <div className="text-end ms-1">
                      <Button
                        variant="info"
                        className="text-light"
                        style={{ fontSize: "0.1rem" }}
                      >
                        <EditIcon style={{ fontSize: "1.2rem" }} />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleOpenDeleteModal(activity)}
                        className="mt-3"
                        style={{ fontSize: "0.1rem" }}
                      >
                        <DeleteIcon style={{ fontSize: "1.2rem" }} />
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
        </div>
      </div>
      <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer cette activité ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutation.isPending }
      />
    </div>
  );
};

export default ActivityInsert;
