import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";
import TableDesny from "../../customComponents/tableDesny";
import SearchIcon from "@mui/icons-material/Search";
import NotificationManager from "../../utils/notificationManger";
import { updateSalaire } from "../../../services/fetchData";

const TableSalaire = ({ AllSalaire }) => {
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [dataSend, setDataSend] = useState([]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", background: "red !important" }),
    []
  );
  const [searchValue, setSearchValue] = useState(""); // État pour stocker la valeur de recherche
  // Gestionnaire d'événements pour la modification de la valeur de recherche
  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);
  // eslint-disable-next-line
  let dataTable = [
    { field: "idSalaire", hide: true },
    { field: "nom", headerName: "Nom", editable: false },
    { field: "prenom", headerName: "Prénom", editable: false },
    { field: "fonction", headerName: "Fonction", editable: false },
    { field: "salaire", headerName: "Salaire", editable: false },
    {
      field: "statut",
      headerName: "Statut",
      cellEditor: "agSelectCellEditor",
      editable: true,
      cellEditorParams: {
        values: ["Payé", "En attente"],
        cellEditorPopup: true,
      },
      cellStyle: function (params) {
        if (params.value === "Payé") {
          return {
            backgroundColor: "#10b981",
            color: "black",
            fontWeight: "500",
          };
        }
        if (params.value === "En attente") {
          return {
            backgroundColor: "#d6bd3d",
            color: "black",
            fontWeight: "500",
          };
        }
        return null;
      },
    },
    {
      field: "date_payement",
      headerName: "Date payement",
      cellEditor: "agDateStringCellEditor",
      editable: true,
    },
    { field: "cle", hide: true },
  ];
  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = useCallback(() => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  }, [notificationQueue]);

  const handelSubmit = useCallback(async (formData) => {
    if (!formData) return null;
    try {
      const response = await updateSalaire(formData);
      if (response && response.success) {
        showSuccessNotification();
      } else {
        showErrorNotification();
      }
    } catch (error) {
      showErrorNotification();
    }
  }, []);

  const handleDataSend = async (updatedData) => {
    setDataSend((prevData) => [...prevData, updatedData]);
  };

  useEffect(() => {
    handelSubmit(dataSend[dataSend.length - 1]);
  }, [handelSubmit, dataSend]);
  return (
    <div style={containerStyle}>
      <div className=" text-end pb-2">
        <InputGroup
          className="ms-auto  custom-placeholder"
          style={{ width: "37%" }}
        >
          <Form.Control
            type="text"
            placeholder="Rechercher ..."
            onChange={handleSearchChange}
            className="border-none rounded-start "
            style={{ background: "", border: "solid 1px white" }}
          />

          <InputGroup.Text
            id="basic-addon2"
            style={{ background: "none", borderLeft: "none !important" }}
            className="rounded-end"
          >
            <SearchIcon className="text-light" />
          </InputGroup.Text>
        </InputGroup>
      </div>
      <TableDesny
        gridStyle={gridStyle}
        dataTable={dataTable}
        searchKeys={[
          "nom",
          "prenom",
          "fonction",
          "salaire",
          "statut",
          "date_payement",
        ]}
        endPoint="get_salaires"
        itemMontant="salaire"
        searchValue={searchValue}
        idItemClick=" idSalaire"
        page="update_employe"
        clickable={false}
        dataSend={handleDataSend}
      />
      <NotificationManager notificationQueue={notificationQueue} />
    </div>
  );
};

export default TableSalaire;
