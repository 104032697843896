import React from "react";
import TableEmploye from "../../components/widgets/list_employees/tableEmploye";
import WidgetEmploye from "../../components/widgets/list_employees/widgetEmploye";

const All_employers = () => {
  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Liste Employés</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Tous les employés</span>
      </p>
      <WidgetEmploye />
      <p className="text-secondary fw-semibold mb-0">Tableau des employés</p>
      <div className="" style={{height:'70vh'}}>
      <TableEmploye />
      </div>
    </div>
  );
};

export default All_employers;
