import React, {useState } from "react";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import ManagementWidgetFonction from "./managementWidgetFonction";
import CardsDesny from "../../customComponents/cardsDesny";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";
import { useCustomQuery } from "../../utils/useCustomQuery ";
const ManagementWidget = () => {
  const [error , setError] = useState(null);

  const {data : bus, isLoading} = useCustomQuery(["nbTotal_bus"], "nbTotal_bus", setError);
  
  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />

  return (
    <div className="row">
      <CardsDesny
        title="Nombre de bus"
        value={bus ? bus.nb_total_bus : 0}
        valueTitle=""
        IconComponent={DirectionsBusIcon}
        FCFA={false}
        customStyle={{ color: "#505b9a" }}
        gridClasses="col-lg-3 col-md-3 col-sm-6 col-xm-12 mb-2"
      />
      <CardsDesny
        title="Entretiens validés"
        value={bus ? bus.nb_entretient_valide : 0}
        valueTitle=""
        IconComponent={DirectionsBusIcon}
        colorIconComponent="text-success"
        FCFA={false}
        gridClasses="col-lg-3 col-md-3 col-sm-6 col-xm-12 mb-2"
      />
      <CardsDesny
        title="Entretiens non validés"
        value={bus ? bus.nb_entretient_non_valide : 0}
        valueTitle=""
        IconComponent={DirectionsBusIcon}
        colorIconComponent="text-danger"
        FCFA={false}
        gridClasses="col-lg-3 col-md-3 col-sm-6 col-xm-12 mb-2"
      />
      <CardsDesny
        title=""
        value=""
        valueTitle=""
        IconComponent=""
        colorIconComponent=""
        FCFA={false}
        gridClasses="col-lg-3 col-md-3 col-sm-6 col-xm-12 mb-2"
      />

      <dir className="col-lg-12 col-md-12 col-sm-12 mb-0">
        <ManagementWidgetFonction />
      </dir>
    </div>
  );
};

export default ManagementWidget;
