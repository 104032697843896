import React, {
  useCallback,
  useMemo,
  useState,
} from "react";
import "../../../../assets/css/style_defaultWidget.css";
import {
  Button,
  Card,
} from "react-bootstrap";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import TableDesny from "../../../customComponents/tableDesny";
import NotificationManager from "../../../utils/notificationManger";
import ModalReservation from "../../../customComponents/modalReservation";

const WidgetReservationsUpdate = ({ idVoyageur }) => {
  const [newRowData, setNewRowData] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    if (idVoyageur === "new") return showAlertNotification();
    setShowModal(true);
  };

  const showAlertNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: false, isAlert: true },
    ]);
  };
  

  const [notificationQueue, setNotificationQueue] = useState([]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(
    () => ({ height: "100%", width: "100%", background: "red !important" }),
    []
  );
  const [searchValue, setSearchValue] = useState(""); // État pour stocker la valeur de recherche
  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  let dataTable = [
    { field: "idReservation", hide: true, editable: false },
    {
      field: "idVoyageur",
      editable: false,
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: {
        defaultOption: "contains",
      },
    },
    { field: "depart", headerName: "Départ", editable: false },
    {
      field: "dateReservation",
      headerName: "Date de réservation",
      editable: false,
    },
    {
      field: "statut",
      headerName: "Statut",
      cellStyle: function (params) {
        if (params.value === "En cours") {
          return {
            backgroundColor: "#d6bd3d",
            color: "black",
            fontWeight: "500",
          };
        }
        if (params.value === "Terminé") {
          return {
            backgroundColor: "#10b981",
            color: "black",
            fontWeight: "500",
          };
        }
        if (params.value === "Annulé") {
          return { backgroundColor: "red", color: "black" };
        }
        return null;
      },
    },
    { field: "arrivee", headerName: "Arrivée", editable: false },
    { field: "cle", hide: true },
  ];

  return (
    <div style={containerStyle}>
      <div className="d-flex">
        <Card.Title className=" text-start pb-2 title">
          Liste des réservations
        </Card.Title>
        <Button className="ms-auto mb-1" onClick={handleOpenModal}>
          <PlaylistAddIcon />
        </Button>
      </div>
      <TableDesny
        gridStyle={gridStyle}
        dataTable={dataTable}
        searchKeys={[
          "dateReservation",
          "heure_depart_bus",
          "depart",
          "arrivee",
        ]}
        endPoint="all_reservations"
        itemMontant="montantTotal"
        searchValue={searchValue}
        idItemClick="idReservation"
        page="update_employe"
        onRowDataChange={(newRowData) => {
          setNewRowData(newRowData);
        }}
        clickable={false}
        modal={true}
        idLink={idVoyageur}
        idDefaultFilter="idVoyageur"
        idDefaultFilterValue={idVoyageur}
      />
      <ModalReservation show={showModal} onClose={() => setShowModal(false)} />
         
      <NotificationManager notificationQueue={notificationQueue} />
    </div>
  );
};

export default WidgetReservationsUpdate;
