import React, {useState } from "react";
import WidgetVoyageurs from "../../components/widgets/actives/widgetVoyageurs";
import TableVoyageurs from "../../components/widgets/actives/tableVoyageurs";
import Reload from "../../components/utils/reload";
import { ErrorConnexion } from "../../components/modal/error500";
import { useCustomQuery } from "../../components/utils/useCustomQuery ";

const Colis = () => {
  const [error, setError] = useState(null);
  const {data: allVoyageursMois, isLoading} = useCustomQuery(["nbReservation_par_mois_agence"], "nbReservation_par_mois_agence", setError);

  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;
  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Liste Voyageurs</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Toutes les Voyageurs</span>
      </p>
      <WidgetVoyageurs allVoyageursMois={allVoyageursMois} />
      <p className="text-secondary fw-semibold mb-0">Tableau des voyageurs</p>
      <div className="" style={{ height: "70vh" }}>
        <TableVoyageurs/>
      </div>
    </div>
  );
};

export default Colis;
