import React from 'react';
import { Card } from 'react-bootstrap';

const CardsDesny = ({title, value, valueTitle, IconComponent, colorIconComponent, customStyle, gridClasses, FCFA }) => {
    const StyleCard = {
        width: "100%",
        border: "none",
        height: "6.5rem",
        background: "#232e45",
      };
    return (
        <div className={gridClasses}>
        <Card style={StyleCard}>
            <Card.Body className=" text-white">
                <div className="d-flex">
                    <h2>{value}</h2>
                    <span className="ms-auto">{(IconComponent && <IconComponent className={`${colorIconComponent} fs-2`} style={customStyle} />) || (FCFA && <p className="ms-auto"> <span className="rounded-2 p-1" style={{ background: "#385761", fontSize: "0.8rem", color: "#4CBA83", }} > FCFA </span> </p>) }</span>
                </div>
                <p className="text-light  fw-semibold">{title} {valueTitle}</p>
            </Card.Body>
        </Card>
    </div>
    );
};

export default CardsDesny;