
export const formatedDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('fr-FR', {
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit'
        });
};

export const dateFormated = (date) => {
const dateFormated = new Date(date);
const day = dateFormated.getDate();
const month = dateFormated.getMonth() + 1;
const year = dateFormated.getFullYear();
return `${day}/${month}/${year}`;
};

export const timeFormated = (time) => {
const timeFormated = time.split(':');
const hour = timeFormated[0];
const minutes = timeFormated[1];
return `${hour}:${minutes}`;
};