import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Ajouter un intercepteur de requête
api.interceptors.request.use(
  (config) => {
    const allCookies = document.cookie;
    const cookieArray = allCookies.split("; ");
    const tokenCookie = cookieArray.find((cookie) =>
      cookie.startsWith("auth_token=")
    );
    if (tokenCookie) {
      const token = tokenCookie.split("=")[1];
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleApiError = (error) => {
  if (error.response && error.response.status === 401) {
    // Le token est expiré ou invalide
    // Rediriger vers la page de connexion ou rafraîchir le token
    document.cookie = "auth_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    window.location.href = "https://app.desnyexpress.mykidd.fr";
  }
  throw error;
};

export const fetchDataOld = async (endpoint, setData, setError) => {
  try {
    const response = await api.get("index.php", { params: { endpoint } });
    setData(response.data);
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchData = async (endpoint) => {
  try {
    const response = await api.get("index.php", { params: { endpoint } });
    return response.data; // Retourne simplement les données
  } catch (error) {
    handleApiError(error);
    throw error; // Lancez l'erreur pour que React Query puisse la gérer
  }
};

export const addActivity = async (intitule, description) => {
  if (!intitule || !description) {
    throw new Error("Intitulé et description sont requis");
  }

  const activityData = {
    intitule: intitule,
    description: description,
  };

  try {
    const response = await api.post(
      `index.php?endpoint=activity`,
      activityData
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const addTask = async (formData) => {
  if (!formData) {
    throw new Error("Formulaire vide");
  }

  try {
    const response = await api.post(`index.php?endpoint=tache`, formData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const utilisateur = async (formData) => {
  if (!formData) {
    throw new Error("Formulaire vide");
  }

  try {
    const response = await api.post(`index.php?endpoint=utilisateur`, formData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const addEmploye = async (formData) => {
  if (!formData) {
    throw new Error("Formulaire vide");
  }
  // Créez un objet contenant les données de l'activité
  const employeData = formData;

  try {
    const response = await api.post(
      `index.php?endpoint=addEmploye`,
      employeData
    );
    return response.data; // Vous pouvez également retourner la réponse si nécessaire
  } catch (error) {
    handleApiError(error);
  }
};

export const addBus = async (busData) => {
  if (!busData) {
    throw new Error("Formulaire vide");
  }

  try {
    const response = await api.post(`index.php?endpoint=bus`, busData);
    return response.data; // Vous pouvez également retourner la réponse si nécessaire
  } catch (error) {
    handleApiError(error);
  }
};

export const addBusDepart = async (formData) => {
  if (!formData) {
    throw new Error("Formulaire vide");
  }
  // Créez un objet contenant les données de l'activité
  const busDepartData = formData;

  try {
    const response = await api.post(
      `index.php?endpoint=busDepart`,
      busDepartData
    );
    return response.data; // Vous pouvez également retourner la réponse si nécessaire
  } catch (error) {
    handleApiError(error);
  }
};

export const addVoyageur = async (formData) => {
  if (!formData) {
    throw new Error("Formulaire vide");
  }
  // Créez un objet contenant les données de l'activité
  const voyageurData = formData;

  try {
    const response = await api.post(
      `index.php?endpoint=voyageurs`,
      voyageurData
    );
    return response.data; // Vous pouvez également retourner la réponse si nécessaire
  } catch (error) {
    handleApiError(error);
  }
};

export const addReservation = async (reservationData) => {
  if (!reservationData) {
    throw new Error("Formulaire vide");
  }
  try {
    const response = await api.post(
      `index.php?endpoint=reservation`,
      reservationData
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
export const addColis = async (data) => {
  if (!data) {
    throw new Error("Formulaire vide");
  }

  try {
    const response = await api.post(`index.php?endpoint=colis`, data);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteElement = async (table, idField, id, cle) => {
  const deleteData = {
    table: table,
    idField: idField,
    id: id,
    cle: cle,
  };

  try {
    const response = await api.post(
      `index.php?endpoint=deleteElement`,
      deleteData,
      {}
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const getElement = async (table, idField, id, setData) => {
  try {
    const response = await api.get("index.php", {
      params: {
        endpoint: "getElement",
        id: id,
        table: table,
        idField: idField,
      },
    });

    if (response.data !== undefined) {
      setData(response.data);
    } else {
      // Gérez le cas où response.data est undefined, par exemple, en ne faisant rien ou en lançant une erreur
    }
  } catch (error) {
    handleApiError(error);
  }
};
export const getItemProce = async (idBusDepart, idBus, setData) => {
  try {
    const response = await api.get("index.php", {
      params: {
        endpoint: "getItemProce",
        idBusDepart: idBusDepart,
        idBus: idBus,
      },
    });
    if (response.data !== undefined) {
      setData(response.data);
    } else {
      // Gérez le cas où response.data est undefined, par exemple, en ne faisant rien ou en lançant une erreur
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const updateSalaire = async (formData) => {
  if (!formData) {
    throw new Error("Formulaire vide");
  }
  try {
    const response = await api.post(`index.php?endpoint=salaire`, formData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// PDF
export const generatePDF = async (endPoint, title, data) => {
  try {
    const response = await api.post(
      "pdf/generate-PDF.php",
      { endpoint: endPoint, title: title, data: data }, // Données à envoyer
      { responseType: "blob" } // Configuration supplémentaire
    );
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title + ".pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    handleApiError(error);
  }
};
// PDF
export const generateWordDocument = async (template, title, data) => {
  try {
    const response = await api.post(
      "pdf/generate-PDFModeleLettre.php", 
      { template: template, title: title, data: data }, 
      { responseType: "blob" } 
    );
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title + ".docx"); // Utilisez .docx pour un document Word
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    handleApiError(error);
  }
};
// EMAIL
export const sendEmail = async (email) => {
  try {
    const response = await api.post("emails/sendEmail.php", email);
    console.log(response.data);
    return response.data;
    } catch (error) {
    handleApiError(error);
  }
};
