import React from "react";
import BarCharts from "../../customComponents/barCharts";
import { formatNumber } from "../../utils/numberFormat";
import { Card } from "react-bootstrap";
import HailIcon from '@mui/icons-material/Hail';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Legend,
  Title,
  Tooltip
);

const WidgetReservationChart = ({ reservationsWeeks }) => {
  let curdate = new Date();
  let curday = curdate.getDay();
  curday = curday === 0 ? 7 : curday;

  let filteredItem = reservationsWeeks.filter((item) => item.jours === curday);
  let sum = filteredItem.reduce(
    (acc, item) => acc + parseInt(item.montantTotal),
    0
  );
  let nbVoyageurs = filteredItem.reduce((acc, item) => acc + parseInt(item.nombreReservations), 0);
  const allWeeks = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];
  return (
    <div className="row">
      <div className="row col-lg-9 col-md-12 col-sm-12 col-xm-12">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12 mt-3">
          <Card style={{ background: "#232e45", marginLeft: "1rem" }}>
            <Card.Body className=" text-white">
              <BarCharts
                barLabels={allWeeks}
                data={reservationsWeeks}
                label="Revenue de la semaine"
                datasetLabel="Agence"
                dataKey="jours"
                indexKey="jours"
                valueKey="montantTotal"
                borderWidth={2}
                borderRadius={5}
                stepSize={1000}
              />
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xm-12 mt-3">
          <Card style={{ background: "#232e45", marginLeft: "1rem" }}>
            <Card.Body className=" text-white">
              <BarCharts
                barLabels={allWeeks}
                data={reservationsWeeks}
                label="Nombre de voyageur de la semaine"
                datasetLabel="Agence"
                dataKey="jours"
                indexKey="jours"
                valueKey="nombreReservations"
                borderWidth={2}
                borderRadius={5}
                stepSize={10}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="col-lg-3 col-md-12 col-sm-12 col-xm-12 mt-3">
        <Card style={{ background: "#232e45", marginLeft: "1rem" }}>
          <Card.Body className=" text-white">
            <span style={{ fontSize: "0.8rem" }}>Révenue d'aujourd'hui</span>
            <div className="d-flex">
              <p className="display-6 pe-2">{formatNumber(sum)}</p>
              <p>
                <span
                  className="rounded-2 p-1"
                  style={{
                    background: "#385761",
                    fontSize: "0.8rem",
                    color: "#4CBA83",
                  }}
                >
                  FCFA
                </span>
              </p>
            </div>
            <div className="bg-in-in p-2 rounded">
            <span style={{ fontSize: "0.8rem" }}>Nombre de voyageurs aujourd'hui</span>
            <p className="pb-0 mb-0 fs-5 text-end">{nbVoyageurs} <HailIcon/></p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default WidgetReservationChart;
