import React from "react";
import AnaliticWidget from "../../components/widgets/dashboard/analiticWidget";
import "../global/style_global.scss";
import AnalyticWidgetDonut from "../../components/widgets/dashboard/analyticWidgetDonut";
import AnalyticWidgetColis from "../../components/widgets/dashboard/analyticWidgetColis";

const Analytics = () => {
  return (
    <div
      className="h-100 p-4 mt-5 pt-3 row g-0"
      style={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <p className="fw-semibold">
        <span className="text-secondary">Dashboard</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Analytic</span>
      </p>
      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
        <AnaliticWidget />
      </div>
      <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
        <AnalyticWidgetDonut />
      </div>
      <div className="mt-2">
        <AnalyticWidgetColis />
      </div>
    </div>
  );
};

export default Analytics;
