import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
//import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
//import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { NavLink } from "react-router-dom";
import { Avatar } from "@mui/material";
import { Badge } from "react-bootstrap";

const Leftbar = ({ user }) => {
  const imagesPath = process.env.REACT_APP_IMAGES_PATH;
  const [collapsed, setCollapsed] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("Dashboard");
  const handleMenuClick = (menuLabel) => {
    setActiveMenu(menuLabel);
  };
  const generateSubMenu = (icon, label, menuItems, defaultOpen) => {
    return (
      <SubMenu icon={icon} label={label} className="bg-color-blue">
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            component={item.component}
            className={getMenuItemClass(item.label)}
            onClick={() => handleMenuClick(item.label)}
          >
            {item.text}
          </MenuItem>
        ))}
      </SubMenu>
    );
  };
  const dashboardMenuItems = [
    { label: "Dashboard", component: <NavLink to="/" />, text: "Par défaut" },
    {
      label: "Analytics",
      component: <NavLink to="/analytics" />,
      text: "Analytics",
    },
    {
      label: "Management",
      component: <NavLink to="/management" />,
      text: "Management",
    },
  ];

  const listeEmployersMenuItems = [
    {
      label: "Liste Employers",
      component: <NavLink to="/list_employees" />,
      text: "Tous les employers",
    },
    {
      label: "Salaires",
      component: <NavLink to="/salaires" />,
      text: "Salaires",
    },
  ];
  const reservationsItems = [
    {
      label: "Départs Bus",
      component: <NavLink to="/depart_bus" />,
      text: "Départs Bus",
    },
    {
      label: "Réservations",
      component: <NavLink to="/reservation" />,
      text: "Total réservations",
    },
  ];
  // const EntrepriseItems = [
  //   {
  //     label: "Bénéfice Net",
  //     component: <NavLink to="/benefice_net" />,
  //     text: "Bénéfice Net",
  //   },
  //   {
  //     label: "Factures",
  //     component: <NavLink to="/factures" />,
  //     text: "Factures",
  //   },
  //   {
  //     label: "Impôts et taxes",
  //     component: <NavLink to="/impots_taxes" />,
  //     text: "Impôts et taxes",
  //   },
  // ];
  const activesItems = [
    {
      label: "Voyageurs",
      component: <NavLink to="/voyageurs" />,
      text: "Voyageurs",
    },
    { label: "Colis", component: <NavLink to="/colis" />, text: "Colis" },
  ];
  if ((user && user.role === "Admin") || (user && user.role ==="Dir"))
    activesItems.push({
      label: "Bus",
      component: <NavLink to="/bus" />,
      text: "Bus",
    });

  const getMenuItemClass = (menuLabel) => {
    return `bg-color-blue ps-4 ${activeMenu === menuLabel ? "active" : ""}`;
  };
  const checkRole = (role) => {
    return user && user.role === role;
  };

  return (
    <Sidebar
      collapsed={collapsed}
      style={{ height: "100vh", border: "none", zIndex: "100" }}
      className={`bg-color-blue col-3 size-text-leftBar pb-1`}
    >
      <div
        className="bg-color-blue text-white"
        style={{ height: "78.56vh", overflowY: "auto" }}
      >
        <div className="mb-4" style={{ width: "100%" }}>
          {!collapsed && (
            <h1
              className="fs-4 ps-2 pt-2"
              onClick={() => setCollapsed(!collapsed)}
            >
              <span>Desny</span> <span className="color-red">Express </span>
              <span className="ps-1 fw-bold">
                <MenuOutlinedIcon />
              </span>
            </h1>
          )}
          {collapsed && (
            <h1
              className="fs-4 pt-2 text-center"
              style={{ paddingLeft: "1.3rem" }}
            >
              {" "}
              <MenuOutlinedIcon
                className="text-center"
                onClick={() => setCollapsed(!collapsed)}
              />
            </h1>
          )}
        </div>
        <Menu
          className=""
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 1)
                return {
                  color: disabled ? "" : "#8993AA",
                  backgroundColor: active ? "white" : undefined,
                };
            },
          }}
        >
          {(checkRole("Admin") || checkRole("Dir")) &&
            generateSubMenu(
              <GridViewOutlinedIcon />,
              "Dashboard",
              dashboardMenuItems
            )}
          {(checkRole("Admin") || checkRole("Dir")) && 
            generateSubMenu(
              <PeopleOutlinedIcon />,
              "Liste Employers",
              listeEmployersMenuItems
            )}
          {(checkRole("Admin") || checkRole("Dir") || checkRole("Empl")) &&
            generateSubMenu(
              <EventAvailableOutlinedIcon />,
              "Réservations",
              reservationsItems
            )}
          {/* {(checkRole('Admin') || checkRole('Empl')) && generateSubMenu(<CorporateFareIcon />, "Entreprise", EntrepriseItems)} */}
          {(checkRole("Admin") || checkRole("Dir") || checkRole("Empl")) &&
            (generateSubMenu(
              <LeaderboardOutlinedIcon />,
              "Actives",
              activesItems
            ))}
        </Menu>
      </div>
      <Menu className="pt-4 bg-color-blue pb-4">
        <MenuItem
          component={<NavLink to="/profil" />}
          className="bg-color-blue text-white mt-5 pt-4"
          icon={
            <Avatar
              alt={user.nom}
              sx={{ width: 30, height: 30 }}
              src={`${imagesPath}/${user.image}`}
            />
          }
        >
          <p className="d-flex mt-3">
            <span className="mt-0 ms-0">
              {user ? user.nom.toUpperCase().substring(0, 1) : ""} {user ? user.prenom : ""}{" "}
              <Badge style={{ fontSize: "65%" }} pill bg="light" text="dark">
                {user ? user.role : ""}
              </Badge>
            </span>
          </p>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default Leftbar;
