import React, { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import {
  getElement,
  addVoyageur,
} from "../../../../services/fetchData";
import "../../../../pages/global/style_global.scss";
import NotificationManager from "../../../utils/notificationManger";
import MenuIcon from "@mui/icons-material/Menu";
import WidgetReservationsUpdate from "./widgetReservationsUpdate";
import ModalColis from "../../../customComponents/modalColis";
import Reload from "../../../utils/reload";
import { useDeleteMutation, usePostMutation } from "../../../utils/useCustomQuery ";
import ConfirmYesNo from "../../../modal/confirmYesNo";

const UpdateVoyageur = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [voyageursData, setVoyageursData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [notificationQueue, setNotificationQueue] = useState([]);
  // eslint-disable-next-line
  const [isRequired, setIsRequired] = useState([]);
  // eslint-disable-next-line
  const saveButtonRef = useRef();

  const [isSaved, setIsSaved] = useState(false);


    // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);

  const currentURL = window.location.href;
  const page = currentURL.split("/");
  const nidVoyageurLink = page.pop();

  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    if (nidVoyageurLink === "new") return showAlertNotification();
    setShowModal(true);
  };

  const initialFormData = useMemo(
    () => ({
      adresse: "",
      civilite: "",
      date_naissance: "",
      email: "",
      nom: "",
      prenom: "",
      numeroTelephone: "",
      idAgence: 0,
      ville: "",
      pays: "République du Congo",
      //  idEmploye: "",
      idVoyageur: nidVoyageurLink,
    }),
    [nidVoyageurLink]
  );

  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    getElement("voyageurs", "idVoyageur", id, (data) => {
      setVoyageursData(data);
      // Mettez à jour initialFormData avec les données de l'employé si elles existent$
      if (data) {
        setFormData({
          ...initialFormData,
          adresse: data.adresse || initialFormData.adresse,
          civilite: data.civilite || initialFormData.civilite,
          date_naissance: data.date_naissance || initialFormData.date_naissance,
          email: data.email || initialFormData.email,
          nom: data.nom || initialFormData.nom,
          prenom: data.prenom || initialFormData.prenom,
          numeroTelephone: data.numeroTelephone || initialFormData.numeroTelephone,
          ville: data.ville || initialFormData.ville,
          pays: data.pays || initialFormData.pays,
          idAgence: data.idAgence || initialFormData.idAgence,
          // idEmploye: data.idEmploye || initialFormData.idEmploye,
          idVoyageur: nidVoyageurLink,
        });
      }
    });
    if (id === "new") {
      setIsEditMode(true);
    }
    // eslint-disable-next-line
  }, [id, nidVoyageurLink, initialFormData]);

  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setVoyageursData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleSaveClick = () => {
    if (!isRequired) {
      setIsEditMode(false);
      showSuccessNotification();
    }
  };
  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  
  const addMutation = usePostMutation(["voyageurs"], addVoyageur);
  const deleteMutation = useDeleteMutation(["voyageurs"]);

  const handleOpenDeleteModal = (voyageursData) => {
    setCurrentToDelete(voyageursData);
    setShowConfirmModal(true);
  };

  const handleVoyageurSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
          setTimeout(() => {
            navigate("/voyageurs");
          }, 700);
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutation.mutate({
        tableName: "voyageurs",
        idFieldName: "idVoyageur",
        id: nidVoyageurLink,
        cle: currentToDelete.cle,
      }, {
        onSuccess: () => {
          setShowConfirmModal(false);
          showSuccessNotification();
          setTimeout(() => {
            navigate("/voyageurs");
          }, 700);
        },
        onError: () => {
          showErrorNotification();
        }, 
      });
    }
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const showAlertNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: false, isAlert: true },
    ]);
  };
  

  const simulateSaveButtonClick = () => {
    saveButtonRef.current.click();
  };

  if(!voyageursData && id !== "new"){
    return <Reload />
  }
  
  return (
    <div>
      <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Liste des voyageurs</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink
          to="/voyageurs"
          className="text-secondary text-decoration-none hover-link-in"
        >
          Tous les voyageurs
        </NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">{voyageursData ? `${voyageursData.nom} ${voyageursData.prenom}` : "Nouveau voyageur"}</span>
      </p>
      <div className="container-fluid my-2">
        <Form className="pt-3" onSubmit={handleVoyageurSubmit}>
          <Card body>
            <div className="d-flex">
              <Card.Title className="pb-3 title">
                Détails du voyageur
              </Card.Title>
              {isEditMode ? (
                <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                  Mode modification
                </Alert>
              ) : (
                <p></p>
              )}
              <Dropdown className="ms-auto">
                <Dropdown.Toggle id="dropdown-basic">
                  Outils <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isEditMode ? (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      type="submit"
                      onClick={() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                      }}
                    >
                      Enregistré
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleEditClick}
                    >
                      Modifier
                    </Dropdown.Item>
                  )}

                <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={handleOpenModal}
                  >
                   Envoyer un colis
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={()=> handleOpenDeleteModal(voyageursData)
                    }
                  >
                    Supprimer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <p className="" style={{ fontSize: "0.8rem !important" }}>
              Le formulaire d'informations du voyageur.
            </p>
            <hr />
            <Row>
              <Col sm="12" lg="12" md="12">
                <Card.Title className="pb-1 title">
                  Informations personnelles
                </Card.Title>
                <p className="fw-semibold">
                  Veuillez fournir ces informations pour le voyageur.
                </p>
                <Form.Group as={Row} className="mb-2" controlId="Personnel">
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Civilité
                  </Form.Label>
                  <Col sm="3" lg="3" md="3">
                    <Form.Select
                      aria-label="Civilité"
                      value={
                        voyageursData
                          ? voyageursData.civilite
                          : formData.civilite
                      }
                      onChange={(event) => handleFieldChange(event, "civilite")}
                      disabled={!isEditMode}
                      required
                    >
                      <option value="" disabled>
                        Choisir civilité
                      </option>
                      <option value="Mr">Mr</option>
                      <option value="Mme">Mme</option>
                      <option value="Autres">Autres</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2" controlId="">
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Nom & prenom
                  </Form.Label>
                  <Col sm="">
                    <Form.Control
                      type="text"
                      placeholder="Nom"
                      value={voyageursData ? voyageursData.nom : formData.nom}
                      onChange={(event) => handleFieldChange(event, "nom")}
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                  <Col sm="">
                    <Form.Control
                      type="text"
                      placeholder="Prenom"
                      value={
                        voyageursData ? voyageursData.prenom : formData.prenom
                      }
                      onChange={(event) => handleFieldChange(event, "prenom")}
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                  <Col sm="">
                    <Row>
                      <Col sm="5" lg="5" md="5" className="mb-2">
                        Date de naissance
                      </Col>
                      <Col sm="7" lg="7" md="7" className="mb-2">
                        <Form.Control
                          type="date"
                          placeholder="Date de naissance"
                          value={
                            voyageursData
                              ? voyageursData.date_naissance
                              : formData.date_naissance
                          }
                          onChange={(event) =>
                            handleFieldChange(event, "date_naissance")
                          }
                          disabled={!isEditMode}
                          required={isRequired}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-2" controlId="">
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Numéro de téléphone
                  </Form.Label>
                  <Col sm="4" md="4" lg="4">
                    <Form.Control
                      type="text"
                      placeholder="Numéro de téléphone"
                      value={
                        voyageursData
                          ? voyageursData.numeroTelephone
                          : formData.numeroTelephone
                      }
                      onChange={(event) =>
                        handleFieldChange(event, "numeroTelephone")
                      }
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>

                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    E-mail
                  </Form.Label>
                  <Col sm="4" md="4" lg="4">
                    <Form.Control
                      type="text"
                      placeholder="E-mail"
                      value={
                        voyageursData ? voyageursData.email : formData.email
                      }
                      onChange={(event) => handleFieldChange(event, "email")}
                      disabled={!isEditMode}
                    />
                  </Col>
                </Form.Group>
                <hr />

                <Form.Group as={Row} className="mb-2" controlId="adress">
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Ville
                  </Form.Label>
                  <Col sm="4" md="4" lg="4">
                    <Form.Select
                  aria-label="Ville"
                  value={
                    voyageursData ? voyageursData.ville : formData.ville
                  }                  
                  onChange={(event) => handleFieldChange(event, "ville")}
                  disabled={!isEditMode}
                  required={isRequired}      
                  >
                  <option value="" disabled>
                    Choisir ville
                  </option>
                  <option value="Brazzaville">Brazzaville</option>
                  <option value="Pointe-Noire">Pointe-Noire</option>
                  <option value="Dolisie">Dolisie</option>
                  <option value="Dolisie">Autre</option>
                </Form.Select>
                  </Col>
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Pays
                  </Form.Label>
                  <Col sm="4" md="4" lg="4">

                <Form.Control
                      type="text"
                      value="République du Congo"                 
                      onChange={(event) => handleFieldChange(event, "pays")}
                      disabled
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-2" controlId="adress">
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Adresse
                  </Form.Label>
                  <Col sm="4" md="4" lg="4">
                    <Form.Control
                      type="text"
                      placeholder="Adresse"
                      value={
                        voyageursData ? voyageursData.adresse : formData.adresse
                      }
                      onChange={(event) => handleFieldChange(event, "adresse")}
                      disabled={!isEditMode}
                    />
                  </Col>
                  <Form.Label column sm="2" lg="2" md="2" className="mb-2">
                    Agence
                  </Form.Label>
                  <Col sm="4" md="4" lg="4">
                    <Form.Select
                      aria-label="Agence"
                      value={
                        voyageursData
                          ? voyageursData.idAgence
                          : formData.idAgence
                      }
                      onChange={(event) => handleFieldChange(event, "idAgence")}
                      disabled={!isEditMode}
                      required={true}
                    >
                   <option value="0" disabled>
                    Choisir l'agence
                  </option>
                      <option value="1">Agence Brazzaville</option>
                      <option value="2">Agence Pointe-Noire</option>
                      <option value="3">Agence Dolisie</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <hr />
              </Col>

            </Row>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId=""
              style={{ height: "40vh" }}
            >
              <Col sm="12" md="12" lg="12" className="pb-3">
                <WidgetReservationsUpdate
                  idVoyageur={id}
                  voyageursData={voyageursData}
                />
              </Col>
            </Form.Group>
          </Card>
          <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
            Enregistrer
          </Button>
        </Form>
        <ModalColis show={showModal} onClose={() => setShowModal(false)} voyageur={formData}/>
        <NotificationManager notificationQueue={notificationQueue} />
        <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer ce voyageur ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutation.isPending }
      />
      </div>
    </div>
  );
};

export default UpdateVoyageur;
