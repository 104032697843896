import React from 'react';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import CardsDesny from '../../customComponents/cardsDesny';

const WidgetColis = ({AllColisMois}) => {
    return (
        <div className="row">
          {AllColisMois.map((item) => (
             <CardsDesny
             title={"Colis "+item.ville}
             value={item ? item.NombreDeColis : 0}
             valueTitle=""
             IconComponent={AllInboxIcon}
             colorIconComponent={"text-"+(item.ville === "Brazzaville" ? "success" : (item.ville === "Pointe-Noire" ? "primary" : "danger"))}
             gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2"
           />
          ))}
        </div>
      );
};

export default WidgetColis;