import React, { useState } from "react";
import { logoDesnyBlack } from "../filePaths"; // Assurez-vous d'importer depuis le bon chemin
import { Spinner } from "react-bootstrap";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Login({ handleLogin }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const now = new Date();
  const blockTime = localStorage.getItem("blockTime");
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown => !passwordShown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier si l'utilisateur est bloqué
    if (blockTime && new Date(blockTime) > now) {
      setError("Veuillez attendre avant de réessayer.");
      return;
    }
    setLoading(true);
    try {
      const response = await fetch("https://app.desnyexpress.myKidd.fr/api/config/login.php?endpoint=connexion_User", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ login, password }),
        }
      );

      if (response.status === 401) {
        setLoading(false);
        setError(
          "Identifiants invalides. Veuillez vérifier votre login et votre mot de passe."
        );
        // Gérer les tentatives de connexion
        let loginAttempts = parseInt(
          localStorage.getItem("loginAttempts") || "0"
        );
        loginAttempts++;

        if (loginAttempts >= 5) {
          // Bloquer pour 5 minutes et réinitialiser les tentatives
          localStorage.setItem(
            "blockTime",
            new Date(now.getTime() + 5 * 60000).toISOString()
          );
          localStorage.setItem("loginAttempts", "0");
        } else {
          localStorage.setItem("loginAttempts", loginAttempts.toString());
        }
      } else {
        const data = await response.json();
        if (data.success) {
          handleLogin(data.user);
          document.cookie =
            "auth_token=" +
            data.token +
            "; path=/; max-age=3600; secure; samesite=strict";
        } else {
          setLoading(false);
          setError(
            "Identifiants invalides. Veuillez vérifier votre login et votre mot de passe."
          );
        }
      }
    } catch (error) {
      setLoading(false);
    }



  };

  return (
    <div className="container col-xl-10 col-xxl-8 px-4 py-5">
      <div className="row align-items-center g-lg-5 py-5">
        <div className="col-lg-7 text-center text-lg-start">
          <h1 className="display-4 fw-bold lh-1 mb-3">Espace de Connexion</h1>
          <p className="col-lg-10 fs-4">
            Soyez vigilant : Ne partagez jamais vos informations confidentielles
            comme les mots de passe. L'équipe Desny Express ne vous demandera
            jamais de les fournir par e-mail ou téléphone !
          </p>
        </div>
        <div className="col-md-10 mx-auto col-lg-5">
          <form onSubmit={handleSubmit}>
            <img src={logoDesnyBlack} alt="Logo Desny Express" width={"100%"} />

            <div className="form-floating mb-3">
              <input
                required
                type="text"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                autoComplete="login"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput">Nom utilisateur</label>
            </div>
            <div className="form-floating mb-3 " style={{position: "relative"}}>
            <input
                required
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                className="form-control"
                id="floatingPassword"
                placeholder="Mot de passe"
            />
            <label htmlFor="floatingPassword">Mot de passe</label>
            <i onClick={togglePasswordVisiblity} className="password-icon" style={{position:"absolute", right: "10px", top: "15px", cursor : "pointer"}}>
                {passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </i>
        </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <button type="submit" className="mb-3 w-100 btn btn-lg btn-danger">
              Connexion{" "}
              {loading && (
                <Spinner animation="border" size="sm" variant="light" />
              )}
            </button>
            <hr className="my-4" />
            <small className="text-muted">&copy; By Kidd 2023–2024.</small>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
