import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  fetchDataOld,
  getElement,
  sendEmail,
  utilisateur,
} from "../../services/fetchData";
import {
  useDeleteMutation,
  usePostMutation,
} from "../../components/utils/useCustomQuery ";
import Reload from "../../components/utils/reload";
import NotificationManager from "../../components/utils/notificationManger";
import MenuIcon from "@mui/icons-material/Menu";
import ConfirmYesNo from "../../components/modal/confirmYesNo";
import MailIcon from '@mui/icons-material/Mail';

const UpdateUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [employe, setEmploye] = useState([]);
  const [email, setEmail] = useState([]);

  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);

  // eslint-disable-next-line
  const [isRequired, setIsRequired] = useState([]);
  const saveButtonRef = useRef();
  const [isEditMode, setIsEditMode] = useState(false);

  const generatePassword = (length) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const [formData, setFormData] = useState({
    idUtilisateur: id,
    nom: "",
    prenom: "",
    email: "",
    login: "",
    role: "Admin",
    idEmploye: 0,
  });

  useEffect(() => {
    getElement("view_all_users", "idUtilisateur", id, (data) => {
      setUserData(data);
      // Mettez à jour initialFormData avec les données de l'employé si elles existent$
      if (data) {
        setFormData({
          idUtilisateur: id,
          nom: data.nom,
          prenom: data.prenom,
          email: data.email,
          login: data.login,
          role: data.role,
          idEmploye: data.idEmploye,
        });
        setEmail(formData)
      }
    });
    try {
      fetchDataOld("all_employes", setEmploye, setError);
    } catch (error) {
      showErrorNotification();
    }

    if (id === "new") {
      setIsEditMode(true);
    }
  }, [id]);

  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setUserData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };


  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleSaveClick = () => {
    if (!isRequired) {
      setIsEditMode(false);
      showSuccessNotification();
    }
  };

  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const addMutation = usePostMutation(["utilisateur"], utilisateur);
  const deleteMutation = useDeleteMutation(["utilisateur"]);

  const handleOpenDeleteModal = (userData) => {
    setCurrentToDelete(userData);
    setShowConfirmModal(true);
  };
 
  useEffect(() => {
    if (id === "new") {
      setFormData({
        ...formData,
        mot_de_passe: generatePassword(8),
      });
    }
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutation.mutate(
        {
          tableName: "utilisateurs",
          idFieldName: "idUtilisateur",
          id: id,
          cle: currentToDelete.cle,
        },
        {
          onSuccess: () => {
            setShowConfirmModal(false);
            showSuccessNotification();
            setTimeout(() => {
              navigate("/profil");
            }, 700);
          },
          onError: () => {
            showErrorNotification();
          },
        }
      );
    }
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const simulateSaveButtonClick = () => {
    if (id === "new") {
      handleShowPassWord();
    }
    saveButtonRef.current.click();
  };

  const [openModalPassword, setOpenModalPassword] = useState(false);

  const handleClosePassWord = () => setOpenModalPassword(false);
  const handleShowPassWord = () => setOpenModalPassword(true);

  if (!userData && id !== "new") {
    return <Reload />;
  }

  return (
    <div>
      <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Liste des utilisateurs</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink
          to="/bus"
          className="text-secondary text-decoration-none hover-link-in"
        >
          Tous les bus
        </NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">
          {userData
            ? `${userData.nom} - ${userData.prenom}`
            : "Nouvel utilisateur"}
        </span>
      </p>

      <div className="container-fluid my-2">
        <Form className="pt-3" onSubmit={handleSubmit}>
          <Card body>
            <div className="d-flex">
              <Card.Title className="pb-3 title">Détails du bus</Card.Title>
              {isEditMode ? (
                <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                  Mode modification
                </Alert>
              ) : (
                <p></p>
              )}
              <Dropdown className="ms-auto">
                <Dropdown.Toggle id="dropdown-basic">
                  Outils <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isEditMode ? (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      type="submit"
                      onClick={() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                      }}
                    >
                      Enregistré
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleEditClick}
                    >
                      Modifier
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        regeneration : true,
                        regenerated_mot_de_passe: generatePassword(8),
                      });
                      setTimeout(() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                        handleShowPassWord();
                      }, 700);
                      sendEmail(formData);
                     
                    }}
                  >
                    Régénérer mot de passe
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => handleOpenDeleteModal(userData)}
                  >
                    Supprimer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <hr />
            <Form.Group as={Row} className="mb-2" controlId="Depart">
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Nom
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Nom"
                  value={userData ? userData.nom : formData.nom}
                  onChange={(event) => handleFieldChange(event, "nom")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Prenom
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Prenom"
                  value={userData ? userData.prenom : formData.prenom}
                  onChange={(event) => handleFieldChange(event, "prenom")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Email
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={userData ? userData.email : formData.email}
                  onChange={(event) => handleFieldChange(event, "email")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>

              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Login
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Login"
                  value={userData ? userData.login : formData.login}
                  onChange={(event) => handleFieldChange(event, "login")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>

              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Employé
              </Form.Label>
              <Col sm="4">
                <Form.Select
                  aria-label="Bus"
                  value={userData ? userData.idEmploye : formData.idEmploye}
                  onChange={(event) => handleFieldChange(event, "idEmploye")}
                  disabled={!isEditMode}
                  required={isRequired}
                >
                  <option value="0" disabled selected>
                    Choisir un employé
                  </option>
                  {employe.map((item) => (
                    <option
                      key={item ? item.idEmploye : 0}
                      value={item ? item.idEmploye : 1}
                    >
                      {item ? item.prenom + " " + item.nom : ""}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Role
              </Form.Label>
              <Col sm="4">
                <Form.Select
                  aria-label="Bus"
                  value={userData ? userData.role : formData.role}
                  onChange={(event) => handleFieldChange(event, "role")}
                  disabled={!isEditMode}
                  required={isRequired}
                >
                  <option value="0" disabled selected>
                    Choisir un role
                  </option>
                  <option value="Admin">Admin</option>
                  <option value="Empl">Employé</option>
                  <option value="Dir">Directeur</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Card>

          <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
            Enregistrer
          </Button>
        </Form>
        <NotificationManager notificationQueue={notificationQueue} />
        <ConfirmYesNo
          description="Êtes-vous sûr de vouloir supprimer cette utilisateur ?"
          functionYes={handleBtnDelete}
          show={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          loading={deleteMutation.isPending}
        />

        <Modal
          show={openModalPassword}
          onHide={handleClosePassWord}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Voici le mot de passe
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center d-flex justify-content-between">

            <Form.Control
              type="text"
              placeholder="Login"
              value={formData.regenerated_mot_de_passe || formData.mot_de_passe}
              disabled={true}
            />
             <Button style={{fontSize:"0.8rem"}} className="ms-3" onClick={()=>{sendEmail(formData)}}>
             <MailIcon />
            </Button>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default UpdateUser;
