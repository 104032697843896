import React, {useState } from "react";
import { Card, Table } from "react-bootstrap";
import "../../../assets/css/style_management.scss";
import "../../../pages/global/style_global.scss";
import { AvatarGroup } from "@mui/lab";
import { Avatar } from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { formatEdit } from "../../utils/numberFormat";
import { useCustomQuery } from "../../utils/useCustomQuery ";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";

const ManagementWidgetFonction = () => {
  const [error, setError] = useState(null);

  const {data : employes, isLoading : isLoadingEmploye} = useCustomQuery(["all_employes"], "all_employes", setError);
  const {data : directeurEmployes, isLoading : isLoadingDirecteurEmploye} = useCustomQuery(["all_directeur_employes"], "all_directeur_employes", setError);
  const {data : infoBus, isLoading: isLoadingInfosBus} = useCustomQuery(["info_bus_management"], "info_bus_management", setError);

  const StyleCard = {
    background: "#232e45",
  };
  const agenceCouleurs = {
    "Agence Pointe-Noire": "#505B9A", 
    "Agence Brazzaville": "#10b981", 
    "Agence Dolisie": "#dc3545", 
  };

   if (isLoadingEmploye || isLoadingDirecteurEmploye || isLoadingInfosBus) return <Reload />;
   if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="row g-2 mt-4">
    {directeurEmployes.map((directeurEmploye) => ( 
      <div className="col-lg-6 col-md-6 mt-3" key= {directeurEmploye.idEmploye}>
          <Card className="p-2" style={StyleCard}>
          <Card.Body className="">
            <Card.Title
              className="w-25 p-2 rounded-2 tex-center text-white"
              style={{ background: agenceCouleurs[directeurEmploye.agence], fontSize: "1rem" }}
            >
              {directeurEmploye.fonction} <AssignmentIndIcon className="ps-2" style={{fontSize: "1.37rem"}}/>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-white">
            {directeurEmploye.civilite}{" "}{directeurEmploye.nom}{" "}{directeurEmploye.prenom}
            </Card.Subtitle>
            <Card.Text className="text-white">
              Directeur de l'{directeurEmploye.agence}
            </Card.Text>
            <AvatarGroup max={5} className="justify-content-start">
              <Card.Text className="me-auto text-white fw-semibold">
                Employés de l'agence:
              </Card.Text>
              {employes.filter((employe) => employe.agence === directeurEmploye.agence).map((employe) => (
                  <Avatar
                  key={employe.idEmploye}
                    alt={employe.nom}
                    src={`/static/images/avatar/${employe.id}.jpg`}
                    />
              ))}
            </AvatarGroup>
            
          </Card.Body>
        </Card>
      </div>
      ))}
        <div className="col-lg-6 col-md-6 mt-3" >
          <Card className="p-2" style={StyleCard}>
          <Card.Body className="">
            <Card.Title
              className="w-25 p-2 rounded-2 tex-center text-white "
              style={{ background: "#038edc", fontSize: "1rem" }}
            >
              Admin <ManageAccountsIcon className="ps-2" style={{fontSize: "1.5rem"}}/>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-white">
              Mr Miguel KIDIMBA 
            </Card.Subtitle>
            <Card.Text className="text-white">
              Administrateur de l'application Desny Express
            </Card.Text>
            <AvatarGroup max={4} className="justify-content-start">
              <Card.Text className="me-auto text-white fw-semibold">
                Autres :
              </Card.Text>
              {employes.filter((employe) => employe.fonction === "Administrateur").map((employe) => (
                  <Avatar key={employe.idEmploye} alt={employe.nom} src={`/static/images/avatar/${employe.id}.jpg`}/>
              ))}
            </AvatarGroup>
            
          </Card.Body>
        </Card>
      </div>
      <div className="col-lg-12 col-md-12 mt-3"> 
          <Table  className="rounded-start">
            <thead className="rounded-start">
              <tr>
                <th style={StyleCard} className="text-white">Modèle bus</th>
                <th style={StyleCard} className="text-white">Immatriculation</th>
                <th style={StyleCard}  className="text-white">Nb voyageurs </th>
                <th style={StyleCard}  className="text-white">Frais carburant</th>
                <th style={StyleCard}  className="text-white">Ration chauffeur</th>
                <th style={{borderRadius:"0rem 0.4rem 0rem 0rem",background: "#232e45"}} className="text-white">Total dépense</th>
              </tr>
            </thead>
            <tbody >
            {infoBus.map((bus) => ( 
              <tr key={bus.immatriculation}>
                <td style={{background:"#323e59", border:"none", color:"white"}} className="text-center">{bus.modele_bus}</td>
                <td style={{background:"#323e59", border:"none", color:"white"}} className="text-center">{bus.immatriculation}</td>
                <td style={{background:"#323e59", border:"none", color:"white"}} className="text-center">{bus.nombre_voyageurs}</td>
                <td style={{background:"#323e59", border:"none", color:"white"}} className="text-end"> 
                  <span>{bus ? formatEdit(bus.frais_carburant) : 0}</span> 
                  <span className="rounded-2 p-1 ms-2" style={{background: "#385761",fontSize: "0.8rem",color: "#4CBA83"}}>FCFA</span>
                </td>
                <td style={{background:"#323e59", border:"none", color:"white"}} className="text-end"> 
                  <span>{bus ?  formatEdit(bus.ration_chauffeur) : 0}</span> 
                  <span className="rounded-2 p-1 ms-2" style={{background: "#385761",fontSize: "0.8rem",color: "#4CBA83"}}>FCFA</span>
                </td>
                <td style={{background:"#323e59", border:"none", color:"white"}} className="text-end"> 
                  <span>{bus ? formatEdit(bus.total_depense) : 0}</span> 
                  <span className="rounded-2 p-1 ms-2" style={{background: "#385761",fontSize: "0.8rem",color: "#4CBA83"}}>FCFA</span>
                </td>     
              </tr>
              ))}
            </tbody>
          </Table>
      </div>
    </div>
  );
};

export default ManagementWidgetFonction;
