import React from "react";
import "./style_global.scss";


const NoMobile = () => {
  return (
    <div className='phone-active' style={{height :'100vh'}}>
      <div
        className="modal modal-alert position-static d-block bg-secondary py-5"
        tabIndex="-1"
        role="dialog"
        id="modalChoice"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded-3 shadow">
            <div className="modal-body p-4 text-center">
              <h5 className="mb-0">Désolé</h5>
              <p className="mb-0">
                L'application Desny Express n'est pas disponible sur mobile.
              </p>
            </div>
            <div className="modal-footer p-0">
              <button
                type="button"
                className="btn btn-lg btn-link fs-6  text-decoration-none col-12 m-0 rounded-0 border-end"
              >
                <strong>Quitter</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMobile;
