import { Component } from 'react';

class MoisActuel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mois: this.getMoisActuel()
    };
  }

  getMoisActuel() {
    const moisEnLettres = [
      'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
      'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];
    const date = new Date();
    return moisEnLettres[date.getMonth()];
  }

  render() {
    return (
        this.state.mois
    );
  }
}

export default MoisActuel;
