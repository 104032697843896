import React from "react";
import { Toast } from "react-bootstrap";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
const SuccessNotif = ({ show, onClose }) => {
  return (
    <div
      className={`position-absolute bottom-0 start-50 translate-middle-x ${
        show ? "fade show" : "fade"
      }` }
      style={{zIndex: '100'}}>
      <Toast show={show} onClose={onClose} bg="success">
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto text-success">
            <TaskAltIcon className="text-success" /> Succès !
          </strong>{" "}
        </Toast.Header>
        <Toast.Body className="text-light">
          Votre opération a été effectuée avec succès !
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default SuccessNotif;
