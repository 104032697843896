import React, {useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { Button, ListGroup } from "react-bootstrap";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { fetchData } from "../../../services/fetchData";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";
import { Link } from "react-router-dom";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";

const WidgetAtivity = () => {
  const [error, setError] = useState(null);

  const { data: activity, isLoading } = useQuery({
    queryKey: ['activity'],
    queryFn: () => fetchData("activity"),
      staleTime: 1000 * 60 * 5, // Les données restent fraîches pendant 5 minutes
      cacheTime: 1000 * 60 * 60, // Les données restent en cache pendant 1 heure, même si inactives
      onError: (error) => {
        setError(error.message);
      },
    });
  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <>
      <div className="d-flex justify-content-between">
        <span></span>
        <h4 className="text-center text-light p-2">
          Activités de l'application
        </h4>
        <span>
          <Link to="/activite">
            <Button className="text-end me-3 mt-2 mb-4">
              <LibraryAddIcon className=" text-light fs-3" />
            </Button>
          </Link>
        </span>
      </div>
      {activity.map((item) => (
        <ListGroup key={item.idActivity} className="me-4 ms-4">
          <ListGroup.Item
            className="mb-3 rounded-2 d-flex text-white border-0"
            style={{ fontSize: "0.7rem", background: "#505B9A" }}
          >
            <FiberNewIcon className="fs-1 me-2" />

            <div>
              <h5 className="mb-0" >{item.intitule}</h5>
              <p className="mb-0">{item.description}</p>
            </div>
          </ListGroup.Item>
        </ListGroup>
      ))}
      
    </>
  );
};

export default WidgetAtivity;
