import React from "react";
import { Toast } from "react-bootstrap";
import BlockIcon from '@mui/icons-material/Block';
const EchecNotif = ({ show, onClose }) => {
  return (
    <div
      className={`position-absolute bottom-0 start-50 translate-middle-x ${
        show ? "fade show" : "fade"
      }` }
      style={{zIndex: '100'}}>
      <Toast show={show} onClose={onClose} bg='danger'>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto text-danger"><BlockIcon className="text-danger" /> Echec !</strong>{" "}

        </Toast.Header>
        <Toast.Body className="text-light">Échec de l'opération. Réessayer ultérieurement !</Toast.Body>
      </Toast>
    </div>
  );
};

export default EchecNotif;
