import React from "react";
import TableBus from "../../components/widgets/actives/tableBus";

const AllBus = () => {

  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Liste Bus</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Tous les Bus</span>
      </p>
      <div className="" style={{ height: "70vh" }}>
        <TableBus/>
      </div>
    </div>
  );
};

export default AllBus;
