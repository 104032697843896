import React, {useState } from "react";
import WidgetReservationChart from "../../components/widgets/reservation/widgetReservationChart";
import TableReservation from "../../components/widgets/reservation/tableReservation";
import Reload from "../../components/utils/reload";
import { ErrorConnexion } from "../../components/modal/error500";
import { useCustomQuery } from "../../components/utils/useCustomQuery ";

const AllReservations = () => {
  const [error, setError] = useState(null);

  const {data : reservationsWeeks, isLoading} = useCustomQuery(["reservation_week"], "reservation_week", setError);
if(isLoading) return <Reload />;
if(error) return <ErrorConnexion error={error} />;

  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Liste Réservations</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Toutes les réservations</span>
      </p>
      <WidgetReservationChart reservationsWeeks={reservationsWeeks} />
      <p className="text-secondary fw-semibold mb-0">Tableau des réservations</p>
      <div className="" style={{ height: "70vh" }}>
        <TableReservation />
      </div>
    </div>
  );
};

export default AllReservations;
