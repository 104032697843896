import React, { useCallback, useMemo, useState } from "react";
import "../../../assets/css/style_defaultWidget.css";
import "../../../pages/global/style_global.scss";

import SearchIcon from "@mui/icons-material/Search";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableDesny from "../../customComponents/tableDesny";
const TableBus = () => {
    const [newRowData, setNewRowData] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(
      () => ({ height: "100%", width: "100%", background: "red !important" }),
      []
    );
    const [searchValue, setSearchValue] = useState(""); // État pour stocker la valeur de recherche
    // Gestionnaire d'événements pour la modification de la valeur de recherche
    const handleSearchChange = useCallback((event) => {
      setSearchValue(event.target.value);
    }, []);

    
  let dataTable = [
    {field: "idBus",hide: true,editable: false},
    {field: "immatriculation",editable: false},
    {field: "modele",editable: false},
    { field: "capacite", editable: false },
    { field: "entretient", editable: false },
    { field: "cle", hide: true },
  ];

    return (
       <div style={containerStyle} >
      {/* <Button
        type="button"
        // onClick={() =>
        //   generatePDF("generate-EmployePDF", "Liste_employes", newRowData)
        // }
        style={{ float: "left", fontSize: "0.8rem" }}
      >
        <PictureAsPdfIcon /> Liste des voyageurs PDF
      </Button> */}
      <div className=" text-end pb-2">
        <InputGroup
          className="ms-auto  custom-placeholder"
          style={{ width: "37%" }}
        >
          <Form.Control
            type="text"
            placeholder="Rechercher ..."
            onChange={handleSearchChange}
            className="border-none rounded-start "
            style={{ background: "", border: "solid 1px white" }}
          />

          <InputGroup.Text
            id="basic-addon2"
            style={{ background: "none", borderLeft: "none !important" }}
            className="rounded-end"
          >
            <SearchIcon className="text-light" />
          </InputGroup.Text>
          <div className="ms-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <MoreHorizIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item style={{ fontSize: "0.9rem" }}>
                  <Link
                    to="/update_bus/new"
                    className="text-decoration-none text-dark"
                  >
                    Ajouter un bus
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </InputGroup>
      </div>
      <TableDesny
        gridStyle={gridStyle}
        dataTable={dataTable}
        searchKeys={["modele", "immatriculation", "capacite", "nom_agence", "entretient"]}
        endPoint="all_bus"
        searchValue={searchValue}
        idItemClick="idBus"
        page="update_bus"
        onRowDataChange={(newRowData) => {
          setNewRowData(newRowData);
        }}
        clickable={true}
      />
    </div>
    );
};

export default TableBus;