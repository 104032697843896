import React, {  } from "react";
import "../global/style_global.scss";
import WidgetAtivity from "../../components/widgets/dashboard/widgetAtivity";
import DefaultWidget from "../../components/widgets/dashboard/defaultWidget";
import DefaultBigWidget from "../../components/widgets/dashboard/defaultBigWidget";

function ParDefaut(props) {
const user = props.user;
  return (
    <div className="h-100 p-4 mt-5 pt-3" style={{ maxHeight: "100vh", overflow: "auto" }}>
      <p className="fw-semibold"><span className="text-secondary">Dashboard</span> <span className="text-light">&#62;</span> <span className="text-light">Defaut</span></p>
      <div className="row g-2">
        <div className="col-lg-7 col-md-12 col-sm-12 col-xm-12 size-tablette">
          <DefaultWidget />
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 col-xm-12 bg-color-blue rounded-2">
         <DefaultBigWidget/>
        </div>
      </div>
      <div className="w-100 h-30 bg-color-blue rounded-2 my-3 overflow-auto" style={{maxHeight: '300px'}}>
       <WidgetAtivity user ={user}/>
      </div>
    </div>
  );
}


export default ParDefaut;
