import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../assets/css/style_ag_out.css";
import { formatEdit } from "../utils/numberFormat";
import NotificationManager from "../utils/notificationManger";
import { AG_GRID_LOCALE_FR } from "../../local.fr.";
import { getElement } from "../../services/fetchData";
import ModalReservation from "./modalReservation";
import Reload from "../utils/reload";
import { useCustomQuery } from "../utils/useCustomQuery ";
import { ErrorConnexion } from "../modal/error500";

const TableDesny = ({
  gridStyle,
  dataTable,
  searchKeys,
  endPoint,
  itemMontant,
  searchValue,
  idItemClick,
  page,
  onRowDataChange,
  clickable,
  dataSend,
  modal,
  idLink,
  idDefaultFilter,
  idDefaultFilterValue,
}) => {
  //const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [error, setError] = useState(null);
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [dataClick, setDataClick] = useState([]);
  const navigate = useNavigate();

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [ReservationData, setReservationData] = useState([]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const [columnDefs] = useState(dataTable);
  const showErrorNotification = useCallback(() => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  }, [notificationQueue]);

  const { data, isLoading } = useCustomQuery([endPoint], endPoint, setError);

  useEffect(() => {
    if (error) {
      showErrorNotification();
    }

    if (data) {
      const formattedData = data.map((row) => ({
        ...row,
        [itemMontant]: formatEdit(row[itemMontant]),
      }));
      setRowData(formattedData);
      onRowDataChange && onRowDataChange(formattedData);
    }
  }, [data, error]);



  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      return searchKeys.some((key) => {
        const value = row[key];
        return value && value.toLowerCase().includes(searchValue.toLowerCase());
      });
    });
  }, [rowData, searchKeys, searchValue]);

  const defaultColDef = useMemo(() => {
    return {
      enableRowGroup: true,
      enablePivot: false,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    };
  }, []);

  const onRowClicked = useCallback(
    (event) => {
      if (clickable) {
        const id = event.data[idItemClick];
        navigate(`/${page}/${id}`);
      } else {
        if (modal) {
          handleOpenModal();
          setDataClick(event.data);
          try {
            // Récupération des données de la réservation
            getElement(
              "reservations",
              "idReservation",
              event.data.idReservation,
              (data) => {
                setReservationData(data);
              }
            );
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
    [navigate, idItemClick, page, clickable, modal]
  );

  const onGridReady = (params) => {
    params.api.setFilterModel({
      [idDefaultFilter]: {
        type: "contains",
        filter: idDefaultFilterValue,
      },
    });
  };

  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div style={gridStyle} className="ag-theme-alpine tablette ">
      <AgGridReact
        rowData={filteredData}
        columnDefs={columnDefs} // Les définitions des colonnes de la grille
        defaultColDef={{
          ...defaultColDef,
          singleClickEdit: true, // Active l'édition en un seul clic
        }}
        groupDisplayType={"groupRows"} // Affiche les lignes groupées
        suppressRowClickSelection={false} // Désactive la sélection de ligne au clic (par défaut à true)
        rowMultiSelectWithClick={true} // Active la sélection de ligne complète au clic
        pagination={true} // Active la pagination
        paginationPageSize={20} // Limite l'affichage à 20 lignes par page
        suppressMovableColumns={true} // Désactive le déplacement des colonnes avec la souris
        onRowClicked={onRowClicked}
        localeText={AG_GRID_LOCALE_FR}
        onCellValueChanged={(event) => {
          dataSend(event.data);
        }}
        onGridReady={onGridReady} // Ajout de la méthode onGridReady
      />
      <NotificationManager notificationQueue={notificationQueue} />
      <ModalReservation
        show={showModal}
        onClose={() => setShowModal(false)}
        idVoyageur={dataClick.idVoyageur}
        dataReservation={ReservationData}
        voyageur={dataClick}
        click = {dataClick && dataClick.idVoyageur ? true : false}
      />
    </div>
  );
};

export default TableDesny;
