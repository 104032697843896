import React from "react";
import ManagementWidget from "../../components/widgets/dashboard/managementWidget";
import ManagementBigWiget from "../../components/widgets/dashboard/managementBigWiget";

const Management = () => {
  return (
    <div className="h-100 p-4 mt-5 pt-3" style={{ maxHeight: "100vh", overflow: "auto" }}>

      <p className="fw-semibold">
        <span className="text-secondary">Dashboard</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Management</span>
      </p>
      <div className="row g-2">
        <div className="col-lg-8 col-md-12 col-sm-12 col-xm-12 size-tablette">
          <ManagementWidget />
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-xm-12 bg-color-blue rounded-2">
          <ManagementBigWiget />
        </div>
      </div>
    </div>
  );
};

export default Management;
