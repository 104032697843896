import React, {useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { fetchData } from "../../../services/fetchData";
import {formatNumber} from '../../utils/numberFormat';
import BarCharts from "../../customComponents/barCharts";
import { useQuery } from "@tanstack/react-query";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Legend,
  Title,
  Tooltip
);

const AnalyticWidgetBarChart = () => {
  const [error, setError] = useState(null);

  const { data: revenueYearAgence, isLoading } = useQuery({
    queryKey: ["revenue_annee_par_agence"],
    queryFn: () => fetchData("revenue_annee_par_agence"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 60,
    onError: (error) => {
      setError(error.message);
    },
  });
  const { data: revenueYear, isLoading: isLoading2 } = useQuery({
    queryKey: ["revenue_annee_agence"],
    queryFn: () => fetchData("revenue_annee_agence"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 60,
    onError: (error) => {
      setError(error.message);
    },
  });

  const allMonths = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  if (isLoading || isLoading2) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div>
      <div className="d-flex">
        <p className="">Revenues</p>
        <div className="ms-auto d-flex">
          <p className="display-5 pe-2">{formatNumber(revenueYear.montant)}</p>
          <p>
            <span
              className="rounded-2 p-1"
              style={{
                background: "#385761",
                fontSize: "0.8rem",
                color: "#4CBA83",
              }}
            >
              FCFA
            </span>
          </p>
        </div>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
      <BarCharts barLabels = {allMonths} data = {revenueYearAgence} label ="Revenue de l'année" datasetLabel = "nom_agence" dataKey = "mois" indexKey = "mois" valueKey = "montant_total" borderWidth={2} borderRadius={5} stepSize={1000}/>
      </div>
    </div>
  );
};

export default AnalyticWidgetBarChart;
