import React from "react";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { formatNumber } from "../../utils/numberFormat";
import CardsDesny from "../../customComponents/cardsDesny";

const WidgetSalaire = ({ AllEmployers, AllSalaire }) => {
  let salaireMois = 0;
  for (let i = 0; i < AllSalaire.length; i++) {
    salaireMois += parseFloat(AllSalaire[i].salaire);
  }

  return (
    <div className="row">
      <CardsDesny title="Total Employés" value={AllEmployers.total} IconComponent={LocalAtmIcon} customStyle={{color: "#4D51D1"}} gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2" />
      <CardsDesny title="Salaire des employés/M" value={formatNumber(salaireMois)} FCFA={true}  gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2" />
      <CardsDesny title="Salaire des employés/A" value={formatNumber(salaireMois * 12)} FCFA={true}  gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2" />
    </div>
  );
};

export default WidgetSalaire;
