import React from "react";
import CardsDesny from "../../customComponents/cardsDesny";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

const WidgetVoyageurs = ({allVoyageursMois}) => {
  return (
    <div className="row">
      {allVoyageursMois.map((item) => (
         <CardsDesny
         title={"Voyageurs "+item.nom_agence}
         value={item ? item.nombre_reservations_mois : 0}
         valueTitle=""
         IconComponent={PeopleOutlineIcon}
         colorIconComponent={"text-"+(item.nom_agence === "Brazzaville" ? "success" : (item.nom_agence === "Pointe-Noire" ? "primary" : "danger"))}
         gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2"
       />
      ))}
    </div>
  );
};

export default WidgetVoyageurs;
