import React, { useCallback, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "../../../assets/css/style_management.scss";
import "../../../pages/global/style_global.scss";
import { Button, Card, Dropdown, Form, Modal, Col, Row, Spinner } from "react-bootstrap";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UpdateIcon from "@mui/icons-material/Update";
import { addTask } from "../../../services/fetchData";
import NotificationManager from "../../utils/notificationManger";
import { dateToFr, timeToFr } from "../../utils/functionChange";
import Reload from "../../utils/reload";
import {useCustomQuery, useDeleteMutation, usePostMutation } from "../../utils/useCustomQuery ";
import {useQueryClient } from "@tanstack/react-query";
import ConfirmYesNo from "../../modal/confirmYesNo";
import { ErrorConnexion } from "../../modal/error500";


const ManagementBigWiget = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentTaskToDelete, setCurrentTaskToDelete] = useState(null);


  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [nombreViewTask, setNombreViewTask] = useState(5);
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [valeurChamp, setValeurChamp] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);

  // NEW-----------------------
  let data = {
    intitule: "",
    description: "",
    heure: "",
    date: "",
    statut: "",
  };

  const [formData, setFormData] = useState(data);
  const [error,setError] = useState(null);
  const queryClient = useQueryClient();
  const { data: tasks, isLoading } = useCustomQuery(['info_tasks'], "info_tasks", setError);


  useEffect(() =>{
    if(selectedTask){
      setFormData({
        cle: selectedTask.cle,
        intitule: selectedTask.intitule,
        description: selectedTask.description,
        heure: selectedTask.heure,
        date: selectedTask.date,
        statut: selectedTask.statut,
        idTache: selectedTask.idTache
    });
    setValeurChamp(selectedTask.date); 
    }
  }, [selectedTask]);

  const voirPlus = () => {
    setNombreViewTask(nombreViewTask + 5);
  };
  
  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = useCallback (() => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  },[notificationQueue]);


  const handleClose = () => {
    setValeurChamp("");
    setFormData("");
    setSelectedTask(null);
    setShow(false);
  };

  const handleShow = useCallback(() => {
    if (valeurChamp !== "") {
      setShow(true);
    }
  }, [valeurChamp]);

  useEffect(() => {
    if (valeurChamp !== "") {
      handleShow();
    }
  }, [valeurChamp, handleShow]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    if (newDate) {
      const dateOriginale = new Date(newDate.$d);
      const year = dateOriginale.getFullYear();
      const month = (dateOriginale.getMonth() + 1).toString().padStart(2, "0");
      const day = dateOriginale.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setValeurChamp(formattedDate);
      setFormData({ ...formData, date: formattedDate });
    } else {
      setValeurChamp("");
    }
  };

  const addMutation = usePostMutation(["info_tasks"], addTask);
  const deleteMutation = useDeleteMutation(["info_tasks"]);

  const handelSubmit = (e) => {
    e.preventDefault();

    addMutation.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(["info_tasks"]);
        setFormData(data);
        setShow(false);
        showSuccessNotification();
      },
      onError: () => {
        showErrorNotification();
      }
    });
  };
  
  const handleOpenDeleteModal = (task) => {
    setCurrentTaskToDelete(task);
    setShowConfirmModal(true);
  };

  const handleBtnDelete = () => {
    if (currentTaskToDelete) {
      deleteMutation.mutate({
        tableName: "taches",
        idFieldName: "idTache",
        id: currentTaskToDelete.idTache,
        cle: currentTaskToDelete.cle,
      }, {
        onSuccess: () => {
          setShowConfirmModal(false);
          showSuccessNotification();
        },
        onError: () => {
          showErrorNotification();
        }, 
        
      });
    }
  };
  

  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div>
      <div className="text-light">
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
          <div
            className="w-100"
            style={{ color: "white !important", width: "500px !important" }}
          >
            <DateCalendar
              className="w-100"
              dayOfWeekFormatter={(_day, weekday) => `${weekday.format("dd")}.`}
              value={selectedDate}
              onChange={handleDateChange} // Gestion de l'événement pour la date
              onClick={handleShow}
            />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Tâche</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handelSubmit}>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Inititule</Form.Label>
                  <Row className="mb-3">
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        required
                        value={formData.intitule}
                        onChange={(e) =>
                          setFormData({ ...formData, intitule: e.target.value })
                        }
                      />
                    </Col>
                    <Col sm="3">
                      <Form.Select
                        aria-label="Statut"
                        required
                        value={formData.statut}
                        onChange={(e) =>
                          setFormData({ ...formData, statut: e.target.value })
                        }
                      >
                        <option value="" disabled>
                          Statut
                        </option>
                        <option  value="Programmé">Programmé</option>
                        <option  value="En cours">En cours</option>
                        <option value="Terminé">Terminé</option>
                        <option value="Abandonné">Abandonné</option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
                <Row className="mb-3">
                  <Col sm="6">
                    <Form.Control
                      type="date"
                      required
                      value={valeurChamp}
                      onChange={(e) => {
                        setValeurChamp(e.target.value);
                        setFormData({ ...formData, date: e.target.value });
                      }}
                    />
                  </Col>
                  <Col sm="6">
                    <Form.Control
                      type="time"
                      required
                      value={formData.heure}
                      onChange={(e) =>
                        setFormData({ ...formData, heure: e.target.value })
                      }
                    />
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.description}
                    onChange={(e) =>
                      setFormData({ ...formData, description: e.target.value })
                    }
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  Fermer
                </Button>
                <Button variant="primary" type="submit">
                  Enrégistrer {addMutation.isPending ? (
                <Spinner animation="border" size="sm" />
              ) : (
                ""
              )}{" "}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </LocalizationProvider>
      </div>
      <div>
        <div className="d-flex p-2">
          <h5 className="text-light">Tâches à faire</h5>
          <Button
            className="ms-auto "
            onClick={voirPlus}
            style={{ fontSize: "0.9rem" }}
          >
            Voir plus
          </Button>
        </div>
        <div style={{ maxHeight: "43vh", overflow: "auto" }}>
          {tasks.slice(0, nombreViewTask).map((task) => (
            <div className="pe-3 ps-3 pt-1 " key={task.idTache}>
              <Card className="bg-in-in rounded-4">
                <Card.Body className="d-flex bg-in-in text-white rounded-3">
                  <div>
                    <Card.Title style={{ fontSize: "0.95rem" }}>
                      {task ? task.intitule : ""}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "0.8rem" }}>
                      <UpdateIcon />
                      <span>
                        {task ? dateToFr(task.date) : ""} |{" "}
                        {task ? timeToFr(task.heure) : ""}
                      </span>{" "}
                      <span
                        className={
                          task.statut === "Terminé"
                            ? "bg-success p-1 ms-4 rounded-2"
                            : task.statut === "Abandonné"
                            ? "bg-danger p-1 ms-4 rounded-2"
                            : task.statut === "En cours"
                            ? "bg-warning p-1 ms-4 rounded-2"
                            : task.statut === "Programmé"
                            ? "bg-primary p-1 ms-4 rounded-2"
                            : "bg-primary p-1 ms-4 rounded-2"
                        }
                      >
                        {task ? task.statut : "Nouveau"}
                      </span>
                    </Card.Text>
                  </div>
                  <Dropdown className="ms-auto">
                    <Dropdown.Toggle id="dropdown-basic">
                      <MoreHorizIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ fontSize: "0.9rem" }}>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedTask(task);
                          setShow(true);
                        }}
                      >
                        Ouvrir
                      </Dropdown.Item>{" "}
                      <Dropdown.Item
                        onClick={() => handleOpenDeleteModal(task)}
                      >
                        Supprimer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Card.Body>
              </Card>
            </div>
          ))}
          <NotificationManager notificationQueue={notificationQueue} />
        </div>
        <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer cette tâche ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutation.isPending}
      />
      </div>
    </div>
  );
};

export default ManagementBigWiget;
