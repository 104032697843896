import React, { useEffect, useState } from "react";
import {Card } from "react-bootstrap";
import AnalyticWidgetBarChart from "./analyticWidgetBarChart";
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import {fetchData} from '../../../services/fetchData';
import CardsDesny from "../../customComponents/cardsDesny";
import { useQuery } from "@tanstack/react-query";
import { ErrorConnexion} from "../../modal/error500";
import Reload from "../../utils/reload";

const AnaliticWidget = () => {
  const [error, setError] = useState(null);
  const { data: nbVoyageurs, isLoading } = useQuery({
    queryKey: ['nbVoyageur_annee_par_agence'],
    queryFn: () => fetchData("nbVoyageur_annee_par_agence"),
      staleTime: 1000 * 60 * 5, 
      cacheTime: 1000 * 60 * 60, 
      onError: (error) => {
        setError(error.message);
      },
    });

    if (isLoading) return <Reload />;
    if (error) return <ErrorConnexion error={error} />;


  return (
      <div className="row">
        <CardsDesny title="Voyageurs" value={nbVoyageurs ? nbVoyageurs.brazzaville_count : 0} valueTitle="Brazzaville" IconComponent={AirlineSeatReclineNormalIcon} colorIconComponent="text-success" customStyle={{color: '#4CBA83'}} FCFA={false} gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2"/>
        <CardsDesny title="Voyageurs" value={nbVoyageurs ? nbVoyageurs.pointe_noire_count : 0} valueTitle="Pointe-Noire" IconComponent={AirlineSeatReclineNormalIcon}  customStyle={{color: '#4D51D1'}} FCFA={false} gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2"/>
        <CardsDesny title="Voyageurs" value={nbVoyageurs ? nbVoyageurs.dolisie_count : 0} valueTitle="Dolisie" IconComponent={AirlineSeatReclineNormalIcon} colorIconComponent="text-danger" customStyle={{color: '#F64E60'}} FCFA={false} gridClasses="col-lg-4 col-md-4 col-sm-6 col-xm-12 mb-2"/>
        <div className="col-lg-12 col-md-12 col-sm-12 col-xm-12 mt-3">
          <Card style={{background: '#232e45'}}>
            <Card.Body className=" text-white">
              <AnalyticWidgetBarChart/>
            </Card.Body>
          </Card>
        </div>
      </div>
  );
};

export default AnaliticWidget;
