import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Reload from "../../../utils/reload";

export const MyCustomCellRenderer = (params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataBusArray, setDataBusArray] = useState(null);
  
  const { allReservations, allBus } = params;

  useEffect(() => {
    const loadData = async () => {
      let dateDepartFind = params.data.dateDepart;
      let heureDepartFind = params.data.heureDepart;
      let idBusFind = params.data.idBus;

      if (!dateDepartFind || !heureDepartFind || !idBusFind) {
        setIsLoading(false);
        return;
      }

      const busInfo = await calculerPlacesParBus(dateDepartFind, heureDepartFind, idBusFind);
      setDataBusArray(busInfo);
      setIsLoading(false); // Mettre isLoading à false après le traitement des données
    };

    loadData();
  }, [params.data]); // Dépendances à params.data pour réexécuter si les données changent
  const calculerPlacesParBus = (dateDepartFind, heureDepartFind, idBusFind) => {
    if (allBus && allReservations) {
      const busFind = allBus.find((bus) => bus.idBus === idBusFind);
      if (!busFind) {
        setDataBusArray(null);
        return false;
      }

      const nombreDeReservations = allReservations.filter(
        (reservation) =>
          reservation.immatriculation_bus === busFind.immatriculation &&
          reservation.dateReservation === dateDepartFind &&
          reservation.heure_depart_bus === heureDepartFind &&
          (reservation.statut === "En cours" || reservation.statut === "Terminé")).length;

      const busInfo = {
        ...busFind,
        nombreDePlaces: nombreDeReservations,
      };
      return busInfo;
    }
  };

  if (isLoading) {
    return <Reload/>;
  }

  
  return (
    (allBus && allReservations) ? (
      !isLoading ? (
        dataBusArray ? (
          <div style={{ position: 'relative', width: '100%' }}>
          <ProgressBar
            className="w-100 mt-2 fw-semibold"
            style={{ height: "20px" }}
            now={(dataBusArray.nombreDePlaces / dataBusArray.capacite) * 100}
          />
          <span style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            zIndex: 1,
            top: 0,
            left: 0,
            color: 'black',
            lineHeight: '20px'
          }}>
            {dataBusArray.capacite - dataBusArray.nombreDePlaces === 0
              ? 'Complet'
              : `${dataBusArray.capacite - dataBusArray.nombreDePlaces} Place(s) restante(s)`}
          </span>
        </div>
        
        ) : (
          <div>Données non disponibles ou en attente de traitement</div>
        )
      ) : (
        <div>Chargement en cours...</div>
      )
    ) : (
      <div>En attente de chargement des données...</div>
    )
  );
};
