import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WifiOffIcon from '@mui/icons-material/WifiOff';
export const ErrorConnexion = ({ error }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="bg-danger">
                <Modal.Title className=" w-100">
                  <WifiOffIcon className="text-danger fs-2  text-white"/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h4>Oups!</h4>	
            <p>Veuillez vérifier votre réseau et réessayer</p>
            <div className="text-end">Code Erreur : {error.message}</div>
            </Modal.Body>
        </Modal>
    );
};
