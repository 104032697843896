import React from "react";
import { Modal } from "react-bootstrap";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const AlertNotif = ({ show, onClose }) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <WarningAmberIcon className="text-warning" /> Alert !
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Merci d'enregistrer la fiche !</p>
      </Modal.Body>
    </Modal>
  );
};

export default AlertNotif;
