import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Form, Card, Row, Col, Spinner } from "react-bootstrap";
import NotificationManager from "../utils/notificationManger";
import { addColis,  fetchDataOld, generateWordDocument, getElement } from "../../services/fetchData";
import { useNavigate } from "react-router-dom";
import { formatEdit } from "../utils/numberFormat";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { dateFormated, timeFormated, formatedDate } from "../utils/formatedDate";
import { useDeleteMutation, usePostMutation } from "../utils/useCustomQuery ";
import ConfirmYesNo from "../modal/confirmYesNo";


const ModalColis = ({ show, onClose, voyageur }) => {
  const navigate = useNavigate();
  const [notificationQueue, setNotificationQueue] = useState([]);
  const [setError] = useState(null);
  const [allBusDepart, setAllBusDepart] = useState([]);
  const [colis, setColis] = useState([]);

    // Modal de confirmation de suppression
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentToDelete, setCurrentToDelete] = useState(null);

  let data = {
    numero: "",
    dateDepot: "",
    destination: "",
    statut: "Nouveau",
    description: "",
    montant: 0.0,
    destinataire: "",
    idAgence: 0,
    idVoyageur: voyageur ? voyageur.idVoyageur : 0,
    idBusdepart: 0,
  };

  const [formData, setFormData] = useState(data);
  const handleClose = () => {
    setFormData(data);
    show = false;
  };
  
  useEffect(() => {
    const fetchDataAndSet = async () => {
      try {
        await fetchDataOld("all_busDepart", setAllBusDepart, setError);  
        if (voyageur && voyageur.idVoyageur){
          await getElement("view_all_colis", 'idVoyageur', voyageur?  voyageur.idVoyageur : 0, (data) => {setColis(data)});
          console.log(colis);
        }
      } catch (error) {
        setError(error);
      }
    };
    fetchDataAndSet();
  }, [setError, voyageur]);

  const addMutationColis = usePostMutation(["reservation"], addColis);
  const deleteMutationColis = useDeleteMutation(["reservation"]);

  const handleOpenDeleteModal = (data) => {
    setCurrentToDelete(data);
    setShowConfirmModal(true);
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutationColis.mutate({
        tableName: "colis",
        idFieldName: "idColis",
        id: currentToDelete.idColis,
        cle: currentToDelete.cle,
      }, {
        onSuccess: () => {
          setShowConfirmModal(false);
          showSuccessNotification();
          onClose();
          handleClose();
          ReloadPage();
        },
        onError: () => {
          showErrorNotification();
        },
      });
    }
  };
  
  const handleFieldChange = (event, fieldName) => {
    const idBusDepart = event.target.value;
    const selectedBusDepart = allBusDepart.find(
      (busDepart) => busDepart.idBusDepart === parseInt(idBusDepart)
    );
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: event.target.value,
      ...(selectedBusDepart
        ? {
            idAgence: selectedBusDepart.idAgence,
            destination: selectedBusDepart.zoneDepart,
          }
        : {}),
    }));
  };

  const generateUniqueId = (destination, dateDepot) => {
    const destinationCode = destination.toUpperCase().substring(0, 3);
    const dateFormatted = formatDate(dateDepot);
    const randomCode = Math.floor(100 + Math.random() * 999) + 1;
    const secretCode = Math.floor(10000 + Math.random() * 99999) + 1;

    return `${destinationCode}-${dateFormatted}${randomCode}-S${secretCode}`;
  };
  
  const ReloadPage = () => {
    const currentURL = window.location.href;
    const page = currentURL.split("/");
    const lastSegment = page.slice(3).join("/");
    navigate("/reload");
    setTimeout(() => {
      navigate("/" + lastSegment);
    }, 300);
  };

  
  const handelSubmitColis = async (e) => {
    e.preventDefault();
    
    const numero = generateUniqueId(formData.destination, formData.dateDepot);
    const updatedFormData = { ...formData, numero };
  
    addMutationColis.mutate(updatedFormData, {
      onSuccess: () => {
        showSuccessNotification();
        handleClose();
        onClose();        
        ReloadPage();
    },
      onError: () => {
        showErrorNotification();
      },
    });

  };
  

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = useCallback(() => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  }, [notificationQueue]);


  const formatDate = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear().toString().substr(-2);
    const month = (dateObj.getMonth() + 1).toString().padStart(1, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");

    return `${year}${month}${day}`;

  };

  console.log(colis);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        onClose();
      }}
      backdrop="static"
      keyboard={false}
      centered
      className="modal-xl"
    >
      <Form onSubmit={handelSubmitColis}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un nouveau colis</Modal.Title>
        </Modal.Header>
        <Modal.Body className="row">
          <Form className="col-7" as={Row}>
            <Form.Group className="mb-3">
              <Form.Select
                required
                onChange={(event) => handleFieldChange(event, "idBusdepart")}
              >
                <option value="0" disabled selected>
                  Départ de bus
                </option>
                {allBusDepart.slice(0,10).map((busDepart) => (
                    <option
                      key={busDepart ? busDepart.idBusDepart : 0}
                      value={busDepart ? busDepart.idBusDepart : 1}
                    >
                      🚏{" "}
                      {busDepart
                        ? `${busDepart.depart} - ${busDepart.arrive} ⏱️${timeFormated(busDepart.heureDepart)}-${dateFormated(busDepart.dateDepart)} 🚍${busDepart.immatriculation}`
                        : ""}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Control
                required
                onChange={(event) => handleFieldChange(event, "destinataire")}
                type="text"
                placeholder="Nom du destinataire"
              />
            </Form.Group>

            <Form.Group className="mb-3" as={Col}>
              <Form.Control
                required
                onChange={(event) => handleFieldChange(event, "destination")}
                type="text"
                placeholder="Zone de retrait"
                value={formData.destination}
              />
            </Form.Group>

            <Form.Label> </Form.Label>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Date de Dépôt</Form.Label>
              <Form.Control
                type="date"
                required
                onChange={(event) => handleFieldChange(event, "dateDepot")}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Select
                required
                onChange={(event) => handleFieldChange(event, "statut")}
              >
                <option disabled selected>
                  Statut
                </option>
                <option>Nouveau</option>
                <option>En cours de livraison</option>
                <option>Livré</option>
                <option>Retourné</option>
                <option>Perdu</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                placeholder="Description"
                as="textarea"
                rows={3}
                onChange={(event) => handleFieldChange(event, "description")}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Montant"
                required
                value={formatEdit(formData.montant)}
                onChange={(event) => handleFieldChange(event, "montant")}
              />
            </Form.Group>
          </Form>
          <div className="col-5">
            <div className="d-flex">
              <h5 className="">Liste des colis envoyés</h5>
              <Button className="ms-auto " style={{ fontSize: "0.9rem" }}>
                Voir plus
              </Button>
            </div>
            <div style={{ maxHeight: "43vh", overflow: "auto" }}>
              {colis ? colis.map((coli) => (
              <div className=" pt-1 ">
                <Card className="bg-in-in rounded-4">
                  <Card.Body className="d-flex bg-in-in text-white rounded-3">
                    <div className="w-100">
                      <Card.Title style={{ fontSize: "0.95rem" }}>
                        Destinataire : {coli.destinataire}
                      </Card.Title>
                      <Card.Text style={{ fontSize: "0.8rem" }}>
                        <span>
                         Depot : <span>{formatedDate(coli.dateDepot)}</span> | Retrait : <span className="">{formatedDate(coli.dateRetrait)}</span>
                        </span>
                      </Card.Text>
                      <div className="d-flex">
                     <span className="fw-bold" style={{fontSize:"0.8rem"}}>N° : {coli.numero}</span>
                     <span
                          style={{
                            backgroundColor:
                              coli.statut === "Livré"
                                ? "#10b981"
                                : coli.statut === "Perdu"
                                ? "#dc3545"
                                : coli.statut === "En cours de livraison"
                                ? "#d6bd3d"
                                : coli.statut === "Nouveau"
                                ? "#0d6efd"
                                : coli.statut === "Retourné"
                                ? "#6c757d"
                                : "#0d6efd", 
                            color: "black",
                            fontWeight: coli.statut === "Livré" || coli.statut === "En cours de livraison" ? "500" : "normal"
                            ,fontSize: "0.8rem"
                          }}
                          className="p-1 rounded-2 ms-auto"
                        >
                          {coli ? coli.statut : "Nouveau"}
                          
                        </span>
                        </div>
                    </div>
                    <div className="d-flex">
                    <DeleteForeverIcon className="ms-auto p-0 btn text-danger" onClick={() =>
                    handleOpenDeleteModal(coli)} />  
                    <PictureAsPdfIcon className="ms-2 p-0 btn text-primary" onClick={() =>generateWordDocument("generate-ColisPDF", "Colis", coli)}/>
                    </div>
              
                  </Card.Body>
                </Card>
              </div>
            )) : ""} 
              <NotificationManager notificationQueue={notificationQueue} />

              <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer ce colis ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutationColis.isPending }
      />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
              onClose();
            }}
          >
            Fermer
          </Button>
          <Button variant="primary" type="submit">
            Sauvegarder {addMutationColis.isPending ?  <Spinner animation="border" size="sm" /> : ""}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalColis;
