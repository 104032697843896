import React, { useState, useEffect } from "react";
import SuccessNotif from "./succesNotif";
import EchecNotif from "./echecNotif";
import AlertNotif from "./alertNotif";

const NotificationManager = ({ notificationQueue }) => {
  const [showA, setShowA] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAlert, setIsAlert] = useState(false);

  const handleClose = () => {
    setShowA(false);
    // Remove the first notification from the queue
    notificationQueue.shift();
  };

  const handleShowNextNotification = () => {
    if (notificationQueue.length > 0) {
      const nextNotification = notificationQueue[0];
      setIsSuccess(nextNotification.isSuccess);
      setIsError(nextNotification.isError);
      setIsAlert(nextNotification.isAlert);
      setShowA(true);
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  };

  useEffect(() => {
    handleShowNextNotification();
  }, [notificationQueue]);

  return (
    <>
      {isSuccess && <SuccessNotif show={showA} onClose={handleClose} />}
      {isError && <EchecNotif show={showA} onClose={handleClose} />}
      {isAlert && <AlertNotif show={showA} onClose={handleClose} />}
    </>
  );
};

export default NotificationManager;
