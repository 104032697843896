import React, { useState } from "react";
import "./style_global.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import {Button } from "react-bootstrap";
import ConfirmYesNo from "../../components/modal/confirmYesNo";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

function Topbar({ handleLogout, user, loading }) {
 // const imagesPath = process.env.REACT_APP_IMAGES_PATH;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const queryClient = useQueryClient();

  const descriptionModal = "Voulez-vous vraiment vous déconnecter ?";
  const ReloadPage = () => {
    queryClient.clear();
    const currentURL = window.location.href;
    const page = currentURL.split("/");
    const lastSegment = page.slice(1).join("/");

    // Naviguer vers la page "/reload"
    navigate("/reload");

    // Utiliser setTimeout pour attendre 1,2 seconde avant de naviguer à nouveau
    setTimeout(() => {
      navigate("/" + lastSegment);
    }, 1200);
  };

  return (
    <header className="fixed-topbar w-100">
      {["md"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-color-blue">
          <Container fluid>
            <Navbar.Brand className="d-flex align">

            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 "></Nav>
                
                <RotateLeftIcon
                  className="text-white ms-1 me-1 mt-2"
                  onClick={ReloadPage}
                />
                <NotificationsActiveOutlinedIcon className="text-white ms-3 me-4 mt-2"></NotificationsActiveOutlinedIcon>
                <Button variant="danger" onClick={handleShow}>
                  <LogoutIcon className="text-light fs-4"></LogoutIcon>
                </Button>
                <ConfirmYesNo
                  description={descriptionModal}
                  functionYes={handleLogout}
                  handleClose={handleClose}
                  show={show}
                  loading={loading}
                />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </header>
  );
}

export default Topbar;
