import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Reload from "../../../utils/reload";
import {
  useDeleteMutation,
  usePostMutation,
} from "../../../utils/useCustomQuery ";
import NotificationManager from "../../../utils/notificationManger";
import MenuIcon from "@mui/icons-material/Menu";
import { addBus, fetchDataOld, getElement } from "../../../../services/fetchData";
import ConfirmYesNo from "../../../modal/confirmYesNo";

const UpdateBus = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [busData, setBusData] = useState(null);
  const [error, setError] = useState(null);
  const [notificationQueue, setNotificationQueue] = useState([]);
    const [employe, setEmploye] = useState([]);

  // Modal de confirmation de suppression
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentToDelete, setCurrentToDelete] = useState(null);

  // eslint-disable-next-line
  const [isRequired, setIsRequired] = useState([]);
  const saveButtonRef = useRef();
  const [isEditMode, setIsEditMode] = useState(false);

  const [formData, setFormData] = useState({
    idBus: id,
    immatriculation: "",
    capacite: "",
    modele: "",
    entretient: "Validé",
    dateEntretient: "",
    idEmploye: 0,
    idAgence: 0,
  });

  useEffect(() => {
    getElement("view_all_bus", "idBus", id, (data) => {
      setBusData(data);
      // Mettez à jour initialFormData avec les données de l'employé si elles existent$
      if (data) {
        setFormData({
          idBus: id,
          immatriculation: data.immatriculation,
          capacite: data.capacite,
          modele: data.modele,
          entretient: data.entretient,
          dateEntretient: data.dateEntretient,
          idEmploye: data.idEmploye,
          idAgence: data.idAgence,
        });
      }
    });
    try {
        fetchDataOld("all_employes", setEmploye, setError);
    } catch (error) {
        showErrorNotification();
    }

    if (id === "new") {
        setIsEditMode(true);
      }
  }, [id]);
  const employeChauffeur = employe.filter(
    (item) => item.fonction === "Chauffeur"
  );

  const updateField = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setBusData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  const handleEditClick = () => {
    setIsEditMode(true);
  };
  const handleSaveClick = () => {
    if (!isRequired) {
      setIsEditMode(false);
      showSuccessNotification();
    }
  };
  const handleFieldChange = (event, fieldName) => {
    updateField(fieldName, event.target.value);
  };

  const addMutation = usePostMutation(["bus"], addBus);
  const deleteMutation = useDeleteMutation(["bus"]);

  const handleOpenDeleteModal = (busData) => {
    setCurrentToDelete(busData);
    setShowConfirmModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    addMutation.mutate(formData, {
      onSuccess: () => {
        showSuccessNotification();
        setTimeout(() => {
          navigate("/bus");
        }, 700);
      },
      onError: () => {
        showErrorNotification();
      },
    });
  };

  const handleBtnDelete = () => {
    if (currentToDelete) {
      deleteMutation.mutate(
        {
          tableName: "bus",
          idFieldName: "idBus",
          id: id,
          cle: currentToDelete.cle,
        },
        {
          onSuccess: () => {
            setShowConfirmModal(false);
            showSuccessNotification();
            setTimeout(() => {
              navigate("/bus");
            }, 700);
          },
          onError: () => {
            showErrorNotification();
          },
        }
      );
    }
  };

  const showSuccessNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: true, isError: false, isAlert: false },
    ]);
  };

  const showErrorNotification = () => {
    setNotificationQueue([
      ...notificationQueue,
      { isSuccess: false, isError: true, isAlert: false },
    ]);
  };

  const simulateSaveButtonClick = () => {
    saveButtonRef.current.click();
  };

  if (!busData && id !== "new") {
    return <Reload />;
  }

  return (
    <div>
      <p className="fw-semibold m-3 mt-5 pt-3">
        <span className="text-secondary">Liste des bus</span>{" "}
        <span className="text-secondary">&#62;</span>{" "}
        <NavLink
          to="/bus"
          className="text-secondary text-decoration-none hover-link-in"
        >
          Tous les bus
        </NavLink>
        <span className="text-light"> &#62;</span>{" "}
        <span className="text-light">
          {busData
            ? `${busData.modele} - ${busData.immatriculation}`
            : "Nouveau départ de bus"}
        </span>
      </p>

      <div className="container-fluid my-2">
        <Form className="pt-3" onSubmit={handleSubmit}>
          <Card body>
            <div className="d-flex">
              <Card.Title className="pb-3 title">Détails du bus</Card.Title>
              {isEditMode ? (
                <Alert variant="warning" className="p-1 ms-auto fw-semibold">
                  Mode modification
                </Alert>
              ) : (
                <p></p>
              )}
              <Dropdown className="ms-auto">
                <Dropdown.Toggle id="dropdown-basic">
                  Outils <MenuIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {isEditMode ? (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      type="submit"
                      onClick={() => {
                        simulateSaveButtonClick();
                        handleSaveClick();
                      }}
                    >
                      Enregistré
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleEditClick}
                    >
                      Modifier
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => handleOpenDeleteModal(busData)}
                  >
                    Entretient
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "0.9rem" }}
                    onClick={() => handleOpenDeleteModal(busData)}
                  >
                    Supprimer
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <p className="" style={{ fontSize: "0.8rem !important" }}>
              Le formulaire d'information du bus.
            </p>
            <hr />
            <Card.Title className="pb-1 title">Bus</Card.Title>

            <p className="fw-semibold">
              Veuillez fournir les informations du bus.
            </p>

            <Form.Group as={Row} className="mb-2" controlId="Depart">
              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Info du bus
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  placeholder="Modele"
                  value={busData ? busData.modele : formData.modele}
                  onChange={(event) => handleFieldChange(event, "modele")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>
              <Col sm="5">
                <Form.Control
                  type="text"
                  placeholder="Immatriculation"
                  value={
                    busData ? busData.immatriculation : formData.immatriculation
                  }
                  onChange={(event) =>
                    handleFieldChange(event, "immatriculation")
                  }
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>
              <Form.Label column sm="2" lg="2" md="2" className="mb-3">
                Date entretient
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="date"
                  placeholder="Date entretient"
                  value={busData ? busData.dateEntretient : formData.dateEntretient}
                  onChange={(event) => handleFieldChange(event, "dateEntretient")}
                  disabled={!isEditMode}
                  required={isRequired}
                />
              </Col>
              <Col sm="5">
                <Row>
                  <Col sm="5">
                    <Form.Label column>Nombre de place</Form.Label>
                  </Col>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      placeholder="Nombre de place"
                      value={
                        busData ? busData.capacite : formData.capacite
                      }
                      onChange={(event) =>
                        handleFieldChange(event, "capacite")
                      }
                      disabled={!isEditMode}
                      required={isRequired}
                    />
                  </Col>
                </Row>
              </Col>

              <Form.Label column sm="2" lg="2" md="2" className="mb-4">
                Entretient
              </Form.Label>
              <Col sm="5">
              <Form.Select
                  aria-label="Bus"
                  value={busData ? busData.entretient : formData.entretient}
                  onChange={(event) => handleFieldChange(event, "entretient")}
                  disabled={!isEditMode}
                  required={isRequired}
                >
                  <option value="Validé">Validé</option>
                  <option value="En attente">En attente</option>
                </Form.Select>
              </Col>

              <Col sm="5">
                <Row>
                  <Col sm="5">
                    <Form.Label column>Chauffeur</Form.Label>
                  </Col>
                  <Col sm="7">
                  <Form.Select
                  aria-label="Bus"
                  value={busData ? busData.idEmploye : formData.idEmploye}
                  onChange={(event) => handleFieldChange(event, "idEmploye")}
                  disabled={!isEditMode}
                  required={isRequired}
                >
                  <option value="0" disabled selected>
                    Choisir le chauffeur
                  </option>
                  {employeChauffeur.map((employe) => (
                    <option
                      key={employe ? employe.idEmploye : 0}
                      value={employe ? employe.idEmploye : 1}
                    >
                       {employe ? employe.prenom + " " + employe.nom : ""}
                    </option>
                  ))}
                </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Form.Label column sm="2" lg="2" md="2" className="mb-3">
                Agence
              </Form.Label>
              <Col sm="5">
                <Form.Select
                  aria-label="Agence"
                  value={busData ? busData.idAgence : formData.idAgence}
                  onChange={(event) => handleFieldChange(event, "idAgence")}
                  disabled={!isEditMode}
                >
                  <option value="0" disabled selected>
                    Choisir l'agence
                  </option>
                  <option value="1">Agence Brazzaville</option>
                  <option value="2">Agence Pointe-Noire</option>
                  <option value="3">Agence Dolisie</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Card>

          <Button type="submit" ref={saveButtonRef} style={{ display: "none" }}>
            Enregistrer
          </Button>
        </Form>
        <NotificationManager notificationQueue={notificationQueue} />
        <ConfirmYesNo
        description="Êtes-vous sûr de vouloir supprimer ce bus ?"
        functionYes={handleBtnDelete}
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        loading={deleteMutation.isPending }
      />
      </div>
    </div>
  );
};

export default UpdateBus;
