import React, {useState } from "react";
import { Card } from "react-bootstrap";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { fetchData } from "../../../services/fetchData";
import {formatNumber} from '../../utils/numberFormat';
import { useQuery } from "@tanstack/react-query";
import Reload from "../../utils/reload";
import { ErrorConnexion } from "../../modal/error500";

const AnalyticWidgetColis = () => {
  const [error, setError] = useState(null);

  const {data : nbColisYear, isLoading} = useQuery({
    queryKey: ['nbTotal_colisYears'],
    queryFn: () => fetchData("nbTotal_colisYears"),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 60,
    onError: (error) => {
      setError(error.message);
    },
  });
  
  
  if (isLoading) return <Reload />;
  if (error) return <ErrorConnexion error={error} />;

  return (
    <div className="row">
      <div className="col-lg-3 col-md-6 mb-1">
        <Card className="style-card">
          <Card.Body className=" text-white">
            <div className="d-flex">
              <span className="text-white">
                <SendIcon className="p-1 fs-2 bg-success rounded-5" />
              </span>
              <div className="ms-auto text-end">
                <p className="fw-semibold mb-0 text-secondary">Colis envoyés</p>
                <span className="fs-2">{nbColisYear ? nbColisYear.nb_statut_livre : 0}</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-lg-3 col-md-6 mb-1">
        <Card className="style-card">
          <Card.Body className=" text-white">
            <div className="d-flex">
              <span className="text-white">
                <ScheduleSendIcon className="p-1 fs-2 bg-warning rounded-5" />
              </span>
              <div className="ms-auto text-end">
                <p className="fw-semibold mb-0 text-secondary">Colis en cours</p>
                <span className="fs-2 ">{nbColisYear ? nbColisYear.nb_statut_encours : 0}</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-lg-3 col-md-6 mb-1">
        <Card className="style-card">
          <Card.Body className=" text-white">
            <div className="d-flex">
              <span className="text-white">
                <CancelScheduleSendIcon className="p-1 fs-2 bg-danger rounded-5" />
              </span>
              <div className="ms-auto text-end">
                <p className="fw-semibold mb-0 text-secondary">Colis perdus</p>
                <span className="fs-2 ">{nbColisYear ? nbColisYear.nb_statut_perdu : 0}</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="col-lg-3 col-md-6 mb-1">
        <Card className="style-card">
          <Card.Body className=" text-white">
            <div className="d-flex">
              <span className="text-white">
                <PointOfSaleIcon className="p-1 fs-2 bg-violet rounded-5" />
              </span>
              <div className="ms-auto text-end">
                <p className="fw-semibold mb-0 ">Montant total des colis</p>
                <div className="d-flex">
                <p className="fs-2 ms-auto pe-2">{formatNumber(nbColisYear.montant)} </p>
                <span className=" m-0"><span className='rounded-2 p-1' style={{ background: '#385761', fontSize: '0.8rem', color:'#4CBA83' }}>FCFA</span></span>
        
                </div>
            </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AnalyticWidgetColis;
