export  const dateToFr = (inputDate) =>{
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(inputDate);
  return date.toLocaleDateString("fr-FR", options);
}
export  const timeToFr = (inputTime) =>{
  const segments = inputTime.split(":");
  if (segments.length >= 2) {
    const hours = segments[0];
    const minutes = segments[1];
    return `${hours}:${minutes}`;
  }
  return inputTime;
}


export const ajusteJSON = (json) => {
  // Ajout d'un séparateur pour diviser les deux objets JSON
  let separator = "}{";
  let parts = json.split(separator);

  // Ré-assemblage des chaînes JSON valides
  let json1 = parts[0] + "}";
  let json2 = "{" + parts[1];

  // Conversion des chaînes en objets JavaScript
  try {
    let obj1 = JSON.parse(json1);
    let obj2 = JSON.parse(json2);

    return [obj1, obj2];
  } catch (e) {
    console.error("Erreur lors de l'analyse JSON:", e);
  }
};