import React, {useState } from 'react';
import WidgetSalaire from "../../components/widgets/list_employees/widgetSalaire";
import TableSalaire from "../../components/widgets/list_employees/tableSalaire";
import Reload from '../../components/utils/reload';
import { ErrorConnexion } from '../../components/modal/error500';
import { useCustomQuery } from '../../components/utils/useCustomQuery ';

const Salaries = () => {

  const [error, setError] = useState(null);
  const {data : AllSalaire, isLoading : isLoadingAllSalaire} = useCustomQuery(["get_salaires"], "get_salaires", setError);
  const {data : AllEmployers, isLoading : isLoadingAllEmployers} = useCustomQuery(["nbAll_employes"], "nbAll_employes", setError);
  
if(isLoadingAllSalaire || isLoadingAllEmployers) return <Reload />;
if(error) return <ErrorConnexion error={error} />;
  
  return (
    <div className="m-3 mt-5 pt-3">
      <p className="fw-semibold">
        <span className="text-secondary">Salaire</span>{" "}
        <span className="text-light">&#62;</span>{" "}
        <span className="text-light">Tous les salaires</span>
      </p>
      <WidgetSalaire AllEmployers ={AllEmployers} AllSalaire={AllSalaire}/>
      <p className="text-secondary fw-semibold mb-0">Tableau des salaires</p>
      <div className="" style={{ height: "70vh" }}>
        <TableSalaire AllSalaire={AllSalaire}/>
      </div>
    </div>
  );
};

export default Salaries;
