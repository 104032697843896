import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteElement, fetchData } from "../../services/fetchData";

export const useCustomQuery = (queryKey, endpoint, setError) => {
    return useQuery({
      queryKey,
      queryFn: () => fetchData(endpoint),
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60, // 1 heure
      onError: (error) => {
        setError(error.message);
      },
    });
  };
  export const useCustomQuerys = (queryKey, dataGet, functionGet, setError) => {
    return useQuery({
      queryKey,
      queryFn: () => functionGet(dataGet),
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60, // 1 heure
      onError: (error) => {
        setError(error.message);
      },
    });
  };
  

  export const useDeleteMutation = (queryKey) => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: ({ tableName, idFieldName, id, cle }) => 
        deleteElement(tableName, idFieldName, id, cle),
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    });
  };

  export const usePostMutation = (queryKey, functionPost) => {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: (formData) => 
        functionPost(formData),
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    });
  };
 