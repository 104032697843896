const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const logoDesnyBlack = `${ROOT_URL}/assets/images/logo_Desny_Express.png`;
// Ajoutez d'autres chemins si nécessaire


// EXPORT DASHBOARD DEFAULT
export const WidgetAtivity = `${ROOT_URL}/components/widgets/dashboard/defaultWidget`


// STYLE style_defaultWidget
export const style_defaultWidget = `${ROOT_URL}/assets/css/style_defaultWidget.css`
