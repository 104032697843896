import React, {  } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

const ConfirmYesNo = ({ description, functionYes, show, handleClose, loading }) => {
    return (
        <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className='text-center'>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>{description}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className='pe-3 ps-3 pt-1 pb-1 bg-danger'  onClick={handleClose}>
              Non
            </Button>
            <Button variant="primary" className='pe-3 ps-3 pt-1 pb-1' onClick={functionYes}>
             Oui {loading ? <Spinner animation="border" size="sm" /> : ""}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
};

export default ConfirmYesNo;