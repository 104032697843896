
export const formatNumber = (number) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
  
    return new Intl.NumberFormat('fr-FR', options).format(number);
  };
export const formatEdit = (initialValue) => {
  const formatNumberWithSpaces = (number) => {
    if (typeof number === 'string') {
      const sanitizedNumber = number.replace(/\s/g, ''); // Supprimer les espaces existants
      const parts = sanitizedNumber.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    }
    return '';
  };
// 100 000 000
// 100 000.000
// 1 0000
  // Formatage initial de la valeur passée en paramètre
  const formattedInitialValue = formatNumberWithSpaces(initialValue);

  return formattedInitialValue;};
  
  
  export const formatEditSend = (initialValue) => {
    const floatValue = parseFloat(initialValue);
    const formattedValue = floatValue.toFixed(2);
    return formattedValue;
  };